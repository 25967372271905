import { License, Package, Payment, PaymentMethod, Subscription } from 'types/models/payment';

/* license methods */
export const LICENSE_GET_REQUEST = 'LICENSE_GET_REQUEST';
export const LICENSE_GET_SUCCESS = 'LICENSE_GET_SUCCESS';
export const LICENSE_GET_FAILURE = 'LICENSE_GET_FAILURE';

/* package */
export const PACKAGE_GET_REQUEST = 'PACKAGE_GET_REQUEST';
export const PACKAGE_GET_SUCCESS = 'PACKAGE_GET_SUCCESS';
export const PACKAGE_GET_FAILURE = 'PACKAGE_GET_FAILURE';

/* payment methods */
export const PAYMENT_METHODS_GET_REQUEST = 'PAYMENT_METHODS_GET_REQUEST';
export const PAYMENT_METHODS_GET_SUCCESS = 'PAYMENT_METHODS_GET_SUCCESS';
export const PAYMENT_METHODS_GET_FAILURE = 'PAYMENT_METHODS_GET_FAILURE';
export const PAYMENT_METHODS_CREATE_REQUEST = 'PAYMENT_METHODS_CREATE_REQUEST';
export const PAYMENT_METHODS_CREATE_SUCCESS = 'PAYMENT_METHODS_CREATE_SUCCESS';
export const PAYMENT_METHODS_CREATE_FAILURE = 'PAYMENT_METHODS_CREATE_FAILURE';
export const PAYMENT_METHODS_DELETE_REQUEST = 'PAYMENT_METHODS_DELETE_REQUEST';
export const PAYMENT_METHODS_DELETE_SUCCESS = 'PAYMENT_METHODS_DELETE_SUCCESS';
export const PAYMENT_METHODS_DELETE_FAILURE = 'PAYMENT_METHODS_DELETE_FAILURE';
export const PAYMENT_METHODS_UPDATE_REQUEST = 'PAYMENT_METHODS_UPDATE_REQUEST';
export const PAYMENT_METHODS_UPDATE_SUCCESS = 'PAYMENT_METHODS_UPDATE_SUCCESS';
export const PAYMENT_METHODS_UPDATE_FAILURE = 'PAYMENT_METHODS_UPDATE_FAILURE';

/* payment history */
export const PAYMENT_HISTORY_REQUEST = 'PAYMENT_HISTORY_REQUEST';
export const PAYMENT_HISTORY_SUCCESS = 'PAYMENT_HISTORY_SUCCESS';
export const PAYMENT_HISTORY_FAILURE = 'PAYMENT_HISTORY_FAILURE';

/* subscription */
export const SUBSCRIPTION_GET_REQUEST = 'SUBSCRIPTION_GET_REQUEST';
export const SUBSCRIPTION_GET_SUCCESS = 'SUBSCRIPTION_GET_SUCCESS';
export const SUBSCRIPTION_GET_FAILURE = 'SUBSCRIPTION_GET_FAILURE';
export const SUBSCRIPTION_CREATE_REQUEST = 'SUBSCRIPTION_CREATE_REQUEST';
export const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS';
export const SUBSCRIPTION_CREATE_FAILURE = 'SUBSCRIPTION_CREATE_FAILURE';
export const SUBSCRIPTION_CANCEL_REQUEST = 'SUBSCRIPTION_CANCEL_REQUEST';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS';
export const SUBSCRIPTION_CANCEL_FAILURE = 'SUBSCRIPTION_CANCEL_FAILURE';

export const SUBSCRIPTION_UPDATE_REQUEST = 'SUBSCRIPTION_UPDATE_REQUEST';
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS';
export const SUBSCRIPTION_UPDATE_FAILURE = 'SUBSCRIPTION_UPDATE_FAILURE';
// export const SUBSCRIPTION_STOP_BEFORE_CANCELLING_REQUEST =
//   'SUBSCRIPTION_STOP_BEFORE_CANCELLING_REQUEST';
// export const SUBSCRIPTION_STOP_BEFORE_CANCELLING_SUCCESS =
//   'SUBSCRIPTION_STOP_BEFORE_CANCELLING_SUCCESS';
// export const SUBSCRIPTION_STOP_BEFORE_CANCELLING_FAILURE =
//   'SUBSCRIPTION_STOP_BEFORE_CANCELLING_FAILURE';

/* license get request */
interface LicenseGetRequestAction {
  type: typeof LICENSE_GET_REQUEST;
  payload: {};
}

interface LicenseGetSuccessAction {
  type: typeof LICENSE_GET_SUCCESS;
  payload: License[];
}

interface LicenseGetFailureAction {
  type: typeof LICENSE_GET_FAILURE;
  payload: {
    error: string;
  };
}

/* package get request */
interface PackageMethodsGetRequestAction {
  type: typeof PACKAGE_GET_REQUEST;
  payload: {};
}

interface PackageMethodsGetSuccessAction {
  type: typeof PACKAGE_GET_SUCCESS;
  payload: Package[];
}

interface PackageMethodsGetFailureAction {
  type: typeof PACKAGE_GET_FAILURE;
  payload: {
    error: string;
  };
}

/* payment method get request */
interface PaymentMethodsGetRequestAction {
  type: typeof PAYMENT_METHODS_GET_REQUEST;
  payload: {};
}

interface PaymentMethodsGetSuccessAction {
  type: typeof PAYMENT_METHODS_GET_SUCCESS;
  payload: PaymentMethod[];
}

interface PaymentMethodsGetFailureAction {
  type: typeof PAYMENT_METHODS_GET_FAILURE;
  payload: {
    error: string;
  };
}

/* payment create request */
interface PaymentMethodsCreateRequestAction {
  type: typeof PAYMENT_METHODS_CREATE_REQUEST;
}

interface PaymentMethodsCreateSuccessAction {
  type: typeof PAYMENT_METHODS_CREATE_SUCCESS;
  payload: any;
}

interface PaymentMethodsCreateFailureAction {
  type: typeof PAYMENT_METHODS_CREATE_FAILURE;
  payload: {
    error: string;
  };
}

/* payment method update */
interface PaymentMethodsUpdateRequestAction {
  type: typeof PAYMENT_METHODS_UPDATE_REQUEST;
}

interface PaymentMethodsUpdateSuccessAction {
  type: typeof PAYMENT_METHODS_UPDATE_SUCCESS;
  payload: any;
}

interface PaymentMethodsUpdateFailureAction {
  type: typeof PAYMENT_METHODS_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

/* payment method delete */
interface PaymentMethodsDeleteRequestAction {
  type: typeof PAYMENT_METHODS_DELETE_REQUEST;
}

interface PaymentMethodsDeleteSuccessAction {
  type: typeof PAYMENT_METHODS_DELETE_SUCCESS;
  payload: any;
}

interface PaymentMethodsDeleteFailureAction {
  type: typeof PAYMENT_METHODS_DELETE_FAILURE;
  payload: {
    error: string;
  };
}

/* payment history */
interface PaymentHistoryRequestAction {
  type: typeof PAYMENT_HISTORY_REQUEST;
  payload: {};
}

interface PaymentHistorySuccessAction {
  type: typeof PAYMENT_HISTORY_SUCCESS;
  payload: Payment[];
}

interface PaymentHistoryFailureAction {
  type: typeof PAYMENT_HISTORY_FAILURE;
  payload: {
    error: string;
  };
}

/* subscription get*/
interface GetSubscriptionRequestAction {
  type: typeof SUBSCRIPTION_GET_REQUEST;
  payload: {};
}

interface GetSubscriptionSuccessAction {
  type: typeof SUBSCRIPTION_GET_SUCCESS;
  payload: Subscription;
}

interface GetSubscriptionFailureAction {
  type: typeof SUBSCRIPTION_GET_FAILURE;
  payload: {
    error: string;
  };
}

/* subscription create*/
interface CreateSubscriptionRequestAction {
  type: typeof SUBSCRIPTION_CREATE_REQUEST;
  payload: {};
}

interface CreateSubscriptionSuccessAction {
  type: typeof SUBSCRIPTION_CREATE_SUCCESS;
  payload: Payment[];
}

interface CreateSubscriptionFailureAction {
  type: typeof SUBSCRIPTION_CREATE_FAILURE;
  payload: {
    error: string;
  };
}

/* subscription update*/
interface UpdateSubscriptionRequestAction {
  type: typeof SUBSCRIPTION_UPDATE_REQUEST;
  payload: {};
}

interface UpdateSubscriptionSuccessAction {
  type: typeof SUBSCRIPTION_UPDATE_SUCCESS;
  payload: Subscription;
}

interface UpdateSubscriptionFailureAction {
  type: typeof SUBSCRIPTION_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

/* subscription cancel*/
// interface CancelSubscriptionRequestAction {
//   type: typeof SUBSCRIPTION_CANCEL_REQUEST;
//   payload: {};
// }

// interface CancelSubscriptionSuccessAction {
//   type: typeof SUBSCRIPTION_CANCEL_SUCCESS;
//   payload: Subscription;
// }

// interface CancelSubscriptionFailureAction {
//   type: typeof SUBSCRIPTION_CANCEL_FAILURE;
//   payload: {
//     error: string;
//   };
// }

// /* prevent subscription cancellation */
// interface ReactiveCancelSubscriptionRequestAction {
//   type: typeof SUBSCRIPTION_STOP_BEFORE_CANCELLING_REQUEST;
//   payload: {};
// }

// interface ReactiveCancelSubscriptionSuccessAction {
//   type: typeof SUBSCRIPTION_STOP_BEFORE_CANCELLING_SUCCESS;
//   payload: Payment[];
// }

// interface ReactiveCancelSubscriptionFailureAction {
//   type: typeof SUBSCRIPTION_STOP_BEFORE_CANCELLING_FAILURE;
//   payload: {
//     error: string;
//   };
// }

export type PaymentAction =
  | LicenseGetRequestAction
  | LicenseGetSuccessAction
  | LicenseGetFailureAction
  | PackageMethodsGetRequestAction
  | PackageMethodsGetSuccessAction
  | PackageMethodsGetFailureAction
  | PaymentMethodsGetRequestAction
  | PaymentMethodsGetSuccessAction
  | PaymentMethodsGetFailureAction
  | PaymentHistoryRequestAction
  | PaymentHistorySuccessAction
  | PaymentHistoryFailureAction
  | PaymentMethodsCreateRequestAction
  | PaymentMethodsCreateSuccessAction
  | PaymentMethodsCreateFailureAction
  | PaymentMethodsUpdateRequestAction
  | PaymentMethodsUpdateSuccessAction
  | PaymentMethodsUpdateFailureAction
  | PaymentMethodsDeleteRequestAction
  | PaymentMethodsDeleteSuccessAction
  | PaymentMethodsDeleteFailureAction
  | GetSubscriptionRequestAction
  | GetSubscriptionSuccessAction
  | GetSubscriptionFailureAction
  // | CancelSubscriptionRequestAction
  // | CancelSubscriptionSuccessAction
  // | CancelSubscriptionFailureAction
  | CreateSubscriptionRequestAction
  | CreateSubscriptionSuccessAction
  | CreateSubscriptionFailureAction
  | UpdateSubscriptionRequestAction
  | UpdateSubscriptionSuccessAction
  | UpdateSubscriptionFailureAction;
// | ReactiveCancelSubscriptionRequestAction
// | ReactiveCancelSubscriptionSuccessAction
// | ReactiveCancelSubscriptionFailureAction;
