import {
  LICENSE_GET_FAILURE,
  LICENSE_GET_REQUEST,
  LICENSE_GET_SUCCESS,
  PACKAGE_GET_FAILURE,
  PACKAGE_GET_REQUEST,
  PACKAGE_GET_SUCCESS,
  PAYMENT_HISTORY_FAILURE,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  PAYMENT_METHODS_CREATE_FAILURE,
  PAYMENT_METHODS_CREATE_REQUEST,
  PAYMENT_METHODS_CREATE_SUCCESS,
  PAYMENT_METHODS_DELETE_FAILURE,
  PAYMENT_METHODS_DELETE_REQUEST,
  PAYMENT_METHODS_DELETE_SUCCESS,
  PAYMENT_METHODS_GET_FAILURE,
  PAYMENT_METHODS_GET_REQUEST,
  PAYMENT_METHODS_GET_SUCCESS,
  PAYMENT_METHODS_UPDATE_FAILURE,
  PAYMENT_METHODS_UPDATE_REQUEST,
  PAYMENT_METHODS_UPDATE_SUCCESS,
  PaymentAction,
  SUBSCRIPTION_CREATE_FAILURE,
  SUBSCRIPTION_CREATE_REQUEST,
  SUBSCRIPTION_CREATE_SUCCESS,
  SUBSCRIPTION_GET_FAILURE,
  SUBSCRIPTION_GET_REQUEST,
  SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIPTION_UPDATE_FAILURE,
  SUBSCRIPTION_UPDATE_REQUEST,
  SUBSCRIPTION_UPDATE_SUCCESS,
} from 'store/actionTypes/paymentActionTypes';

import { License, Package, Payment, PaymentMethod, Subscription } from 'types/models/payment';

export type PaymentHistoryState = {
  data?: Payment[];
  loading: boolean;
  error: string | null;
};

export type PaymentMethodState = {
  data?: PaymentMethod[];
  loading?: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error: string | null;
};

export type SubscriptionState = {
  data?: Subscription;
  loading?: boolean;
  creating?: boolean;
  updating?: boolean;
  error: string | null;
};

export type LicenseState = {
  data?: License[];
  loading: boolean;
  error: string | null;
};

export type PackageState = {
  data: Package[];
  loading: boolean;
  error: string | null;
};

interface PaymentState {
  paymentHistory: PaymentHistoryState;
  paymentMethods: PaymentMethodState;
  subscription: SubscriptionState; // current latest subscription
  packages: PackageState;
  licenses: LicenseState;
}

export const initialSubscriptionState: Subscription = {
  id: '',
  stripeCustomerId: '',
  stripeSubscriptionId: '',
  companyId: '',
  packageId: '',
  numberOfLicenses: 0,
  currentPeriodStartAt: 0,
  currentPeriodEndAt: 0,
  subscriptionStartAt: 0,
  subscriptionEndAt: 0,
  trialPeriodEndAt: 0,
  gracePeriodEndAt: 0,
  subscriptionCanceledAt: 0,
  status: 'ACTIVE',
  package: {
    id: '',
    name: '',
    licensePrice: 0,
    subscriptionPlan: 'monthly',
    details: '',
    pricing: {}
  },
  licenses: [],
};

const initialState: PaymentState = {
  subscription: {
    data: initialSubscriptionState,
    loading: false,
    updating: false,
    creating: false,
    error: null,
  },
  paymentHistory: {
    data: [],
    loading: false,
    error: null,
  },
  paymentMethods: {
    data: [],
    loading: false,
    creating: false,
    deleting: false,
    error: null,
  },
  licenses: {
    data: [],
    loading: false,
    error: null,
  },
  packages: {
    data: [],
    loading: false,
    error: null,
  },
};

const paymentReducer = (state = initialState, action: PaymentAction): PaymentState => {
  switch (action.type) {
    //packages
    case PACKAGE_GET_REQUEST:
      return { ...state, packages: { ...state.packages, loading: true } };
    case PACKAGE_GET_SUCCESS:
      return {
        ...state,
        packages: { ...state.packages, data: action.payload, loading: false },
      };
    case PACKAGE_GET_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: action.payload.error,
        },
      };
    //licences
    case LICENSE_GET_REQUEST:
      return { ...state, licenses: { ...state.licenses, loading: true } };
    case LICENSE_GET_SUCCESS:
      return {
        ...state,
        licenses: { ...state.licenses, data: action.payload, loading: false },
      };
    case LICENSE_GET_FAILURE:
      return {
        ...state,
        licenses: {
          ...state.licenses,
          loading: false,
          error: action.payload.error,
        },
      };
    //payment history
    case PAYMENT_HISTORY_REQUEST:
      return { ...state, paymentHistory: { ...state.paymentHistory, loading: true } };
    case PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistory: { ...state.paymentHistory, data: action.payload, loading: false },
      };
    case PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: false,
          error: action.payload.error,
        },
      };
    //payment method get
    case PAYMENT_METHODS_GET_REQUEST:
      return { ...state, paymentMethods: { ...state.paymentMethods, loading: true } };
    case PAYMENT_METHODS_GET_SUCCESS:
      return {
        ...state,
        paymentMethods: { ...state.paymentMethods, data: action.payload, loading: false },
      };
    case PAYMENT_METHODS_GET_FAILURE:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: action.payload.error,
        },
      };

    //payment method create
    case PAYMENT_METHODS_CREATE_REQUEST:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          creating: true,
        },
      };
    case PAYMENT_METHODS_CREATE_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          creating: false,
        },
      };
    case PAYMENT_METHODS_CREATE_FAILURE:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          creating: false,
          error: action.payload.error,
        },
      };

    //payment method delete
    case PAYMENT_METHODS_DELETE_REQUEST:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          deleting: true,
          error: null,
        },
      };
    case PAYMENT_METHODS_DELETE_SUCCESS:
      const current = state.paymentMethods.data;
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: current?.filter((ele) => ele.id !== action.payload?.paymenthodId),
          deleting: false,
          error: null,
        },
      };
    case PAYMENT_METHODS_DELETE_FAILURE:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          deleting: false,
          error: action.payload.error,
        },
      };

    //payment method update
    case PAYMENT_METHODS_UPDATE_REQUEST:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          updating: true,
          error: null,
        },
      };
    case PAYMENT_METHODS_UPDATE_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: state.paymentMethods.data?.map((ele) =>
            ele.id === action.payload?.id
              ? { ...ele, ...action.payload }
              : { ...ele, isDefault: false }
          ),
          updating: false,
          error: null,
        },
      };
    case PAYMENT_METHODS_UPDATE_FAILURE:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          updating: false,
          error: action.payload.error,
        },
      };

    //subscription get
    case SUBSCRIPTION_GET_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: true,
          error: null,
          data: initialSubscriptionState,
        },
      };
    case SUBSCRIPTION_GET_SUCCESS:
      return {
        ...state,
        subscription: { ...state.subscription, data: action.payload, loading: false, error: null },
      };
    case SUBSCRIPTION_GET_FAILURE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: false,
          data: state?.subscription?.data,
          error: action.payload.error,
        },
      };

    //subscription create
    case SUBSCRIPTION_CREATE_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          creating: true,
          error: null,
        },
      };
    case SUBSCRIPTION_CREATE_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          creating: false,
          error: null,
        },
      };
    case SUBSCRIPTION_CREATE_FAILURE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          creating: false,
          error: action.payload.error,
        },
      };
    //subscription update
    case SUBSCRIPTION_UPDATE_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          updating: true,
          error: null,
        },
      };
    case SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          data: { ...state.subscription.data, ...action.payload },
          updating: false,
          error: null,
        },
      };
    case SUBSCRIPTION_UPDATE_FAILURE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          updating: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default paymentReducer;
