import { Client } from 'types/models/client';

export const CLIENT_GET_REQUEST = 'CLIENT_GET_REQUEST';
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_FAILURE = 'CLIENT_GET_FAILURE';

export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAILURE = 'CLIENT_CREATE_FAILURE';

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAILURE = 'CLIENT_UPDATE_FAILURE';

export const CLIENT_IMPORT_REQUEST = 'CLIENT_IMPORT_REQUEST';
export const CLIENT_IMPORT_SUCCESS = 'CLIENT_IMPORT_SUCCESS';
export const CLIENT_IMPORT_FAILURE = 'CLIENT_IMPORT_FAILURE';

export const ADD_IMPORT_TASK = 'ADD_IMPORT_TASK';
export const UPDATE_IMPORT_PROGRESS = 'UPDATE_IMPORT_PROGRESS';
export const REMOVE_IMPORT_TASK = 'REMOVE_IMPORT_TASK';

interface ClientGetRequestAction {
  type: typeof CLIENT_GET_REQUEST;
  payload: {};
}

interface ClientGetSuccessAction {
  type: typeof CLIENT_GET_SUCCESS;
  payload: { list: Client[]; count: number };
}

interface ClientGetFailureAction {
  type: typeof CLIENT_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ClientCreateRequestAction {
  type: typeof CLIENT_CREATE_REQUEST;
  payload: {};
}

interface ClientCreateSuccessAction {
  type: typeof CLIENT_CREATE_SUCCESS;
  payload: Client;
}

interface ClientCreateFailureAction {
  type: typeof CLIENT_CREATE_FAILURE;
  payload: {
    error: string;
  };
}

interface ClientUpdateRequestAction {
  type: typeof CLIENT_UPDATE_REQUEST;
  payload: {};
}

interface ClientUpdateSuccessAction {
  type: typeof CLIENT_UPDATE_SUCCESS;
  payload: Client;
}

interface ClientUpdateFailureAction {
  type: typeof CLIENT_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

interface ProjectImportRequestAction {
  type: typeof CLIENT_IMPORT_REQUEST;
  payload: {};
}

interface ProjectImportSuccessAction {
  type: typeof CLIENT_IMPORT_SUCCESS;
  payload: any;
}

interface ProjectImportFailureAction {
  type: typeof CLIENT_IMPORT_FAILURE;
  payload: {
    error: string;
  };
}

interface ProjectImportAddTaskAction {
  type: typeof ADD_IMPORT_TASK;
  payload: any;
}

interface ProjectImportUpdateProgressAction {
  type: typeof UPDATE_IMPORT_PROGRESS;
  payload: any;
}

interface ProjectImportRemoveTaskAction {
  type: typeof REMOVE_IMPORT_TASK;
  payload: any;
}

export type ClientAction =
  | ClientGetRequestAction
  | ClientGetSuccessAction
  | ClientGetFailureAction
  | ClientCreateRequestAction
  | ClientCreateSuccessAction
  | ClientCreateFailureAction
  | ClientUpdateRequestAction
  | ClientUpdateSuccessAction
  | ClientUpdateFailureAction
  | ProjectImportRequestAction
  | ProjectImportSuccessAction
  | ProjectImportFailureAction
  | ProjectImportAddTaskAction
  | ProjectImportUpdateProgressAction
  | ProjectImportRemoveTaskAction;
