import { User, UserOnlineStatus } from 'types/models/user';

export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAILURE = 'USER_GET_FAILURE';
export const GET_USER_ONLINE_REQUEST = 'GET_USER_ONLINE_REQUEST';
export const GET_USER_ONLINE_SUCCESS = 'GET_USER_ONLINE_SUCCESS';
export const GET_USER_ONLINE_FAILURE = 'GET_USER_ONLINE_FAILURE';
export const SET_USER_ONLINE_REQUEST = 'SET_USER_ONLINE_REQUEST';
export const SET_USER_ONLINE_SUCCESS = 'SET_USER_ONLINE_SUCCESS';
export const SET_USER_ONLINE_FAILURE = 'SET_USER_ONLINE_FAILURE';

interface UserGetRequestAction {
  type: typeof USER_GET_REQUEST;
  payload: {};
}

interface UserGetSuccessAction {
  type: typeof USER_GET_SUCCESS;
  payload: User[];
}

interface UserGetFailureAction {
  type: typeof USER_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface GetUserOnlineRequestAction {
  type: typeof GET_USER_ONLINE_REQUEST;
  payload: {};
}

interface GetUserOnlineSuccessAction {
  type: typeof GET_USER_ONLINE_SUCCESS;
  payload: UserOnlineStatus[];
}

interface GetUserOnlineFailureAction {
  type: typeof GET_USER_ONLINE_FAILURE;
  payload: {
    error: string;
  };
}

interface SetUserOnlineRequestAction {
  type: typeof SET_USER_ONLINE_REQUEST;
  payload: {};
}

interface SetUserOnlineSuccessAction {
  type: typeof SET_USER_ONLINE_SUCCESS;
  payload: UserOnlineStatus;
}

interface SetUserOnlineFailureAction {
  type: typeof SET_USER_ONLINE_FAILURE;
  payload: {
    error: string;
  };
}

export type UserAction =
  | UserGetRequestAction
  | UserGetSuccessAction
  | UserGetFailureAction
  | GetUserOnlineRequestAction
  | GetUserOnlineSuccessAction
  | GetUserOnlineFailureAction
  | SetUserOnlineRequestAction
  | SetUserOnlineSuccessAction
  | SetUserOnlineFailureAction;
