/**
 *
 * NOTE
 * use define routes in route.ts file
 * implement this structure to handle all routes in one place
 * if its hard to maintain. please devide routes seperately (public/ private/ app/ or each layout wise)
 * accoring to the current code structure there is lazy loading issue
 *
 * */

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import routes from './routes';
import RouteHandler from './RouteHandler';
import SuspensedView from 'components/UI/SuspensedView';
import { ROUTE_BASE_NAME } from 'constants/index';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

/**
 *
 * defined routes in the route.ts file
 *
 */
const Routing: React.FC = () => {
  //populate the routes recursively
  const renderRoutes = (items: any[]) =>
    items.map(
      ({ path, permissions, component: Component, authority, routes: nestedRoutes, type }, key) => {
        return (
          <Route
            key={key}
            path={path}
            element={
              <RouteHandler permissions={permissions} authority={authority} type={type}>
                <SuspensedView>
                  <Component />
                </SuspensedView>
              </RouteHandler>
            }
          >
            {nestedRoutes && nestedRoutes.length > 0 && <Route>{renderRoutes(nestedRoutes)}</Route>}
          </Route>
        );
      }
    );

  const Redirect: React.FC = () => {
    window.location.href = `/${ROUTE_BASE_NAME}`;
    return null;
  };

  return (
    <>
      {/* this should static web page. redirect to /app temp... */}
      <Router>
        <Routes>
          <Route path='/' element={<Redirect />}></Route>
          <Route path='/linkedin' element={<LinkedInCallback />} />
        </Routes>
      </Router>
      <Router basename={`/${ROUTE_BASE_NAME}`}>
        <Routes>{renderRoutes(routes)}</Routes>
      </Router>
    </>
  );
};

export default Routing;
