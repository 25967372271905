import { DELETE, GET, PATCH, POST, PUT, request } from '../adapter';

export async function query(payload: any) {
  return await request('users', GET, {}, payload);
}

export async function queryUsers(payload: any) {
  return request(`users`, GET, {}, payload);
}

export function queryById(id: string) {
  return request(`users/${id}`, GET, {}, {});
}

export async function updateProfile(data: Object) {
  return await request(`user/profile`, PATCH, data, {});
}

export async function getProfile() {
  return await request(`users/user/profile`, GET, {}, {});
}

export function create(payload: any) {
  return request('users/create', POST, payload, {});
}

export function update(payload: any) {
  const { id } = payload;
  return request(`users/${id}`, PUT, payload, {});
}

export async function queryCurrent(): Promise<any> {
  return request('/currentUser');
}

export async function queryNotices(): Promise<any> {
  return request('/notices');
}

export async function queryUsersByRole(roleName: string): Promise<any> {
  return request(`users?roleName=${roleName}`, GET);
}

export async function uploadUserProfilePicture(id: string, data: any) {
  return request(`user/profile-pic`, PATCH, data);
}

export async function getUserProfilePicture(id: string) {
  return request(`user/profile-pic`, GET);
}

export async function changePassword(payload: any) {
  const { userId } = payload;
  return request(`users/${userId}/change-password`, PATCH, payload);
}

export async function checkEmail(email: string) {
  return request(`users/check-email/${email}`, GET);
}

export async function deleteUser(payload: any) {
  const { id } = payload;
  return request(`users/${id}`, DELETE, payload, {});
}

export function getCompanyUsers(companyId: string, query: any = {}) {
  return request(`companies/${companyId}/users`, GET, {}, query);
}

export function getNextEmployeeNumber() {
  return request(`reference/employee-number`, GET);
}

export function getRoles() {
  return request('/roles');
}

export function getUserEmails() {
  return request(`/user-emails`, GET);
}

export function importUsers(payload: any) {
  return request(`import-users`, POST, payload);
}

export function resetPassword(payload: any) {
  return request(`/reset-client-password`, POST, payload);
}

export function fetchUserOnlineStatus(companyId: string, payload: any) {
  return request(`companies/${companyId}/get-online-status`, GET, {}, payload);
}

export function setOnlineStatus(payload: any) {
  return request(`users/${payload?.userId}/online-status`, POST, payload);
}
