import {
  PROJECT_USERS_GET_REQUEST,
  PROJECT_USERS_GET_SUCCESS,
  PROJECT_USERS_GET_FAILURE,
  PROJECT_TASKS_GET_FAILURE,
  PROJECT_TASKS_GET_REQUEST,
  PROJECT_TASKS_GET_SUCCESS,
  SELECTED_PROJECT_GET_REQUEST,
  SELECTED_PROJECT_GET_SUCCESS,
  SELECTED_PROJECT_GET_FAILURE,
  ProjectAction,
  PROJECT_IMPORT_REQUEST,
  PROJECT_IMPORT_SUCCESS,
  PROJECT_IMPORT_FAILURE,
  ADD_IMPORT_TASK,
  UPDATE_IMPORT_PROGRESS,
  REMOVE_IMPORT_TASK,
  PROJECT_LOGO_UPDATE_REQUEST,
  PROJECT_LOGO_UPDATE_SUCCESS,
  PROJECT_LOGO_UPDATE_FAILURE,
  PROJECT_LOGO_GET_REQUEST,
  PROJECT_LOGO_GET_SUCCESS,
  PROJECT_LOGO_GET_FAILURE,
} from 'store/actionTypes/projectActionTypes';

import { User } from 'types/models/user';
import { ProjectsData } from 'types/models/project';

export interface ImportTask {
  queueId: string;
  fileName: string;
  progress: number;
  error: string | null;
}

export interface ImportProject {
  data: any;
  loading: boolean;
  error: string | null;
}
export interface ProjectState {
  projectLogo: { data: any; loading: boolean; updating: boolean; error: string | null };
  projectUsers: { data: User[]; loading: boolean; error: string | null };
  projectTasks: { data: ProjectsData[]; loading: boolean; error: string | null }; //TODO: change any to Task
  selectedProject: { data: ProjectsData[]; loading: boolean; error: string | null };
  importTasks: ImportTask[];
  importProject: ImportProject;
}

const initialState: ProjectState = {
  projectLogo: {
    data: "",
    loading: false,
    updating: false,
    error: null,
  },
  projectUsers: {
    data: [],
    loading: false,
    error: null,
  },
  projectTasks: {
    data: [],
    loading: false,
    error: null,
  },
  selectedProject: {
    data: [],
    loading: false,
    error: null,
  },
  importTasks: [],
  importProject: {
    data: {},
    loading: false,
    error: '',
  },
};

const projectReducer = (state = initialState, action: ProjectAction): ProjectState => {
  switch (action.type) {
    case PROJECT_USERS_GET_REQUEST:
      return {
        ...state,
        projectUsers: { ...state.projectUsers, loading: true },
      };
    case PROJECT_USERS_GET_SUCCESS:
      return {
        ...state,
        projectUsers: { ...state.projectUsers, data: action.payload, loading: false },
      };
    case PROJECT_USERS_GET_FAILURE:
      return {
        ...state,
        projectUsers: {
          ...state.projectUsers,
          loading: false,
          error: action.payload.error,
        },
      };
    case PROJECT_TASKS_GET_REQUEST:
      return {
        ...state,
        projectTasks: { ...state.projectTasks, loading: true },
      };
    case PROJECT_TASKS_GET_SUCCESS:
      return {
        ...state,
        projectTasks: { ...state.projectTasks, data: action.payload, loading: false },
      };
    case PROJECT_TASKS_GET_FAILURE:
      return {
        ...state,
        projectTasks: {
          ...state.projectTasks,
          loading: false,
          error: action.payload.error,
        },
      };
    case SELECTED_PROJECT_GET_REQUEST:
      return {
        ...state,
        selectedProject: { ...state.selectedProject, loading: true },
      };

    case SELECTED_PROJECT_GET_SUCCESS:
      return {
        ...state,
        selectedProject: { ...state.selectedProject, data: action.payload, loading: false },
      };

    case SELECTED_PROJECT_GET_FAILURE:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          loading: false,
          error: action.payload.error,
        },
      };

    case PROJECT_IMPORT_REQUEST:
      return {
        ...state,
        importProject: { ...state.importProject, loading: true },
      };
    case PROJECT_IMPORT_SUCCESS:
      return {
        ...state,
        importProject: {
          ...state.importProject,
          data: action.payload?.importProject || {},
          loading: false,
        },
      };
    case PROJECT_IMPORT_FAILURE:
      return {
        ...state,
        importProject: {
          ...state.importProject,
          loading: false,
          error: action.payload.error,
        },
      };
    case ADD_IMPORT_TASK:
      return {
        ...state,
        importTasks: [...state.importTasks, action.payload.task],
      };
    case UPDATE_IMPORT_PROGRESS:
      const updatedTasks = state.importTasks.map((task) =>
        task.queueId === action.payload.queueId
          ? { ...task, fileName: action.payload.fileName, progress: action.payload.progress }
          : task
      );
      return {
        ...state,
        importTasks: updatedTasks,
      };
    case REMOVE_IMPORT_TASK:
      const filteredTasks = state.importTasks.filter(
        (task) => task.queueId !== action.payload.queueId
      );
      return {
        ...state,
        importTasks: filteredTasks,
      };
    // update project logo
    case PROJECT_LOGO_UPDATE_REQUEST:
      return {
        ...state,
        projectLogo: { ...state.projectLogo, updating: true },
      };
    case PROJECT_LOGO_UPDATE_SUCCESS:
      return {
        ...state,
        projectLogo: {
          ...state.projectLogo,
          data: action.payload,
          updating: false,
        },
      };
    case PROJECT_LOGO_UPDATE_FAILURE:
      return {
        ...state,
        projectLogo: {
          ...state.projectLogo,
          updating: false,
          error: action.payload.error,
        },
      };
    // get project logo
    case PROJECT_LOGO_GET_REQUEST:
      return {
        ...state,
        projectLogo: { ...state.projectLogo, loading: true },
      };
    case PROJECT_LOGO_GET_SUCCESS:
      return {
        ...state,
        projectLogo: {
          ...state.projectLogo,
          data: action.payload,
          loading: false,
        },
      };
    case PROJECT_LOGO_GET_FAILURE:
      return {
        ...state,
        projectLogo: {
          ...state.projectLogo,
          loading: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
};

export default projectReducer;
