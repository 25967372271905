import { includes } from 'lodash';
import {
  WORKSCHEDULES_GET_REQUEST,
  WORKSCHEDULES_GET_FAILURE,
  WORKSCHEDULES_GET_SUCCESS,
  WorkScheduleAction,
  USER_WORKSCHEDULE_GET_REQUEST,
  USER_WORKSCHEDULE_GET_SUCCESS,
  USER_WORKSCHEDULE_GET_FAILURE,
  ALL_USER_WORKSCHEDULES_GET_FAILURE,
  ALL_USER_WORKSCHEDULES_GET_SUCCESS,
  CLEAR_SELECTED_USERS,
  SET_SELECTED_USERS,
  SET_ACTION_KEY,
  USER_WORKSCHEDULE_CREATE_REQUEST,
  USER_WORKSCHEDULE_CREATE_SUCCESS,
  USER_WORKSCHEDULE_CREATE_FAILURE,
  USER_WORKSCHEDULE_UPDATE_REQUEST,
  USER_WORKSCHEDULE_UPDATE_SUCCESS,
  USER_WORKSCHEDULE_UPDATE_FAILURE,
  USER_WORKSCHEDULE_DELETE_REQUEST,
  USER_WORKSCHEDULE_DELETE_SUCCESS,
  USER_WORKSCHEDULE_DELETE_FAILURE,
} from 'store/actionTypes/workscheduleActionTypes';

type Shift = {
  index: number;
  shiftEnd: { day: string; time: number };
  shiftStart: { day: string; time: number };
  workingHours: number;
  minWorkHours: number;
  break: any[];
};

type WorkSchedule = {
  id: string;
  companyId: string;
  firstName: string;
  isActive: string;
  lastName: string;
  scheduleName: string;
  schedule: {
    scheduleEndAt: string;
    scheduleStartAt: string;
    shifts: Shift[];
  };
};

type UserWorkSchedule = {
  id: string;
  userId: string;
  assignedAt: string;
  createdAt: string;
  isIterate: string;
  updatedAt: string;
  validFrom: string;
  validTo: string;
  workScheduleId: string;
  workSchedule: {
    id: string;
    companyId: string;
    createdAt: string;
    isTemplate: string;
    minimumShiftDuration: string;
    name: string;
    type: string;
    updatedAt: string;
    schedule: {
      scheduleEndAt: string;
      scheduleStartAt: string;
      shifts: Shift[];
    };
  };
};

type WorkScheduleListState = {
  data: WorkSchedule[];
  total: number;
  loading: boolean;
  error: string | null;
  creating: boolean;
  updating: boolean;
};

type UserWorkScheduleState = {
  data: UserWorkSchedule;
  loading: boolean;
  error: string | null;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
};

interface WorkScheduleState {
  workschedules: WorkScheduleListState;
  allWorkschedules: WorkScheduleListState;
  userWorkschedule: UserWorkScheduleState;
  selectedUsers: string[];
  actionKey: string;
}

const initialState: WorkScheduleState = {
  workschedules: {
    data: [],
    total: 0,
    loading: false,
    creating: false,
    updating: false,
    error: null,
  },
  allWorkschedules: {
    data: [],
    total: 0,
    loading: false,
    creating: false,
    updating: false,
    error: null,
  },
  userWorkschedule: {
    data: {
      id: '',
      userId: '',
      assignedAt: '',
      createdAt: '',
      isIterate: '',
      updatedAt: '',
      validFrom: '',
      validTo: '',
      workScheduleId: '',
      workSchedule: {
        id: '',
        companyId: '',
        createdAt: '',
        isTemplate: '',
        minimumShiftDuration: '',
        name: '',
        type: '',
        updatedAt: '',
        schedule: {
          scheduleEndAt: '',
          scheduleStartAt: '',
          shifts: [],
        },
      },
    },
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
    error: null,
  },
  selectedUsers: [],
  actionKey: '',
};

const workScheduleReducer = (
  state = initialState,
  action: WorkScheduleAction
): WorkScheduleState => {
  let currentWorkschedules = state.workschedules.data;

  switch (action.type) {
    case WORKSCHEDULES_GET_REQUEST:
      return {
        ...state,
        workschedules: { ...state.workschedules, loading: true },
      };
    case WORKSCHEDULES_GET_SUCCESS:
      return {
        ...state,
        workschedules: {
          ...state.workschedules,
          data: action.payload?.data,
          total: action.payload?.total || 0,
          loading: false,
        },
      };
    case WORKSCHEDULES_GET_FAILURE:
      return {
        ...state,
        workschedules: {
          ...state.workschedules,
          loading: false,
          error: action.payload.error,
        },
      };

    case USER_WORKSCHEDULE_GET_REQUEST:
      return {
        ...state,
        userWorkschedule: { ...state.userWorkschedule, loading: true },
      };
    case USER_WORKSCHEDULE_GET_SUCCESS:
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          data: action.payload,
          loading: false,
        },
      };
    case USER_WORKSCHEDULE_GET_FAILURE:
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          loading: false,
          error: action.payload.error,
        },
      };
    case ALL_USER_WORKSCHEDULES_GET_SUCCESS:
      return {
        ...state,
        allWorkschedules: {
          ...state.allWorkschedules,
          data: action.payload,
          loading: false,
        },
      };
    case ALL_USER_WORKSCHEDULES_GET_FAILURE:
      return {
        ...state,
        allWorkschedules: {
          ...state.allWorkschedules,
          error: action.payload.error,
        },
      };
    case USER_WORKSCHEDULE_CREATE_REQUEST:
      return {
        ...state,
        userWorkschedule: { ...state.userWorkschedule, creating: true },
      };
    case USER_WORKSCHEDULE_CREATE_SUCCESS:
      const userIds = action?.payload?.userIds;
      if (action?.payload?.userId) {
        currentWorkschedules = currentWorkschedules.map((ele) =>
          ele?.id === action?.payload?.userId
            ? {
                ...ele,
                ...action?.payload?.workschedule,
                scheduleName: action?.payload?.workSchedule?.name,
                schedule: action?.payload?.workSchedule?.schedule,
              }
            : ele
        );
      } else if (userIds?.length) {
        currentWorkschedules = currentWorkschedules.map((ele) =>
          includes(userIds, ele?.id)
            ? {
                ...ele,
                ...action?.payload,
                scheduleName: action?.payload?.name,
              }
            : ele
        );
      }
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          data: action.payload,
          creating: false,
        },
        workschedules: {
          ...state.workschedules,
          data: currentWorkschedules,
        },
        selectedUsers: userIds?.length ? [] : state?.selectedUsers,
      };
    case USER_WORKSCHEDULE_CREATE_FAILURE:
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          creating: false,
          error: action.payload.error,
        },
      };

    case USER_WORKSCHEDULE_UPDATE_REQUEST:
      return {
        ...state,
        userWorkschedule: { ...state.userWorkschedule, updating: true },
      };
    case USER_WORKSCHEDULE_UPDATE_SUCCESS:
      const updatedList = currentWorkschedules.map((ele) =>
        ele?.id === action?.payload?.userId
          ? {
              ...ele,
              ...action?.payload?.workschedule,
              scheduleName: action?.payload?.workSchedule?.name,
              schedule: action?.payload?.workSchedule?.schedule,
            }
          : ele
      );
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          data: action.payload,
          updating: false,
        },
        workschedules: {
          ...state.workschedules,
          data: updatedList,
        },
      };
    case USER_WORKSCHEDULE_UPDATE_FAILURE:
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          updating: false,
          error: action.payload.error,
        },
      };

    case USER_WORKSCHEDULE_DELETE_REQUEST:
      return {
        ...state,
        userWorkschedule: { ...state.userWorkschedule, deleting: true },
      };
    case USER_WORKSCHEDULE_DELETE_SUCCESS:
      if (action?.payload?.userId) {
        currentWorkschedules = currentWorkschedules.map((ele) =>
          ele?.id === action?.payload?.userId
            ? {
                ...ele,
                scheduleName: '',
                schedule: {
                  scheduleEndAt: '',
                  scheduleStartAt: '',
                  shifts: [],
                },
              }
            : ele
        );
      } else if (action?.payload?.userIds) {
        currentWorkschedules = currentWorkschedules.map((ele) =>
          includes(action?.payload?.userIds, ele?.id)
            ? {
                ...ele,
                scheduleName: '',
                schedule: {
                  scheduleEndAt: '',
                  scheduleStartAt: '',
                  shifts: [],
                },
              }
            : ele
        );
      }
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          data: initialState?.userWorkschedule?.data,
          deleting: false,
        },
        workschedules: {
          ...state.workschedules,
          data: currentWorkschedules,
        },
        selectedUsers: action?.payload?.userIds?.length ? [] : state?.selectedUsers,
      };
    case USER_WORKSCHEDULE_DELETE_FAILURE:
      return {
        ...state,
        userWorkschedule: {
          ...state.userWorkschedule,
          deleting: false,
          error: action.payload.error,
        },
      };

    case SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case CLEAR_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: [],
      };

    case SET_ACTION_KEY:
      return {
        ...state,
        actionKey: action.payload,
      };

    default:
      return state;
  }
};

export default workScheduleReducer;
