import {
  AuthAction,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
} from '../actionTypes/authActionTypes';

interface AuthState {
  user: any | null;
  loading: boolean;
  error: string | null;
  data: {
    failedLoginAttempts: number;
    isCaptchaVerifyRequired: boolean;
  };
}

const initialState: AuthState = {
  user: null,
  loading: false, // use same loading state. scenarios occured seperately
  error: null, // use same error state. scenarios occured seperately
  data: {
    failedLoginAttempts: 0,
    isCaptchaVerifyRequired: false,
  },
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    //login
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, user: action.payload.user, loading: false, error: null };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {
          failedLoginAttempts: action.payload.data.failedLoginAttempts,
          isCaptchaVerifyRequired: action.payload.data.isCaptchaVerifyRequired,
        },
      };
    //logout
    case LOGOUT_REQUEST:
      return { ...state, user: null, loading: false, error: null };
    //forgot password
    case FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    //reset password
    case RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    //sign up
    case SIGN_UP_REQUEST:
      return { ...state, loading: true, error: null };
    case SIGN_UP_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case SIGN_UP_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    //verify otp
    case VERIFY_OTP_REQUEST:
      return { ...state, loading: true, error: null };
    case VERIFY_OTP_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case VERIFY_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    //resend otp
    case RESEND_OTP_REQUEST:
      return { ...state, loading: true, error: null };
    case RESEND_OTP_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RESEND_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
};

export default authReducer;
