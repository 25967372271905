export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: {
    user: string;
  };
}

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  payload: {
    data: {
      failedLoginAttempts: number;
      isCaptchaVerifyRequired: boolean;
    };
    error: string;
  };
}

interface LogoutAction {
  type: typeof LOGOUT_REQUEST;
}

interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUEST;
}

interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: {};
}

interface ForgotPasswordFailureAction {
  type: typeof FORGOT_PASSWORD_FAILURE;
  payload: {
    error: string;
  };
}

interface ResetPasswordRequestAction {
  type: typeof RESET_PASSWORD_REQUEST;
}

interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: {};
}

interface ResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
  payload: {
    error: string;
  };
}
interface SignUpRequestAction {
  type: typeof SIGN_UP_REQUEST;
}

interface SignUpSuccessAction {
  type: typeof SIGN_UP_SUCCESS;
  payload: {};
}

interface SignUpFailureAction {
  type: typeof SIGN_UP_FAILURE;
  payload: {
    error: string;
  };
}

interface ResendOTPRequestAction {
  type: typeof RESEND_OTP_REQUEST;
}

interface ResendOTPSuccessAction {
  type: typeof RESEND_OTP_SUCCESS;
  payload: {};
}

interface ResendOTPFailureAction {
  type: typeof RESEND_OTP_FAILURE;
  payload: {
    error: string;
  };
}

interface VerifyOTPRequestAction {
  type: typeof VERIFY_OTP_REQUEST;
}

interface VerifyOTPSuccessAction {
  type: typeof VERIFY_OTP_SUCCESS;
  payload: {};
}

interface VerifyOTPFailureAction {
  type: typeof VERIFY_OTP_FAILURE;
  payload: {
    error: string;
  };
}

export type AuthAction =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | SignUpRequestAction
  | SignUpSuccessAction
  | SignUpFailureAction
  | ResendOTPRequestAction
  | ResendOTPSuccessAction
  | ResendOTPFailureAction
  | VerifyOTPRequestAction
  | VerifyOTPSuccessAction
  | VerifyOTPFailureAction;
