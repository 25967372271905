import { Spin } from 'antd';
import React, { FC, Suspense } from 'react';

interface SplashScreenProps {}

const LoadingView: FC<SplashScreenProps> = () => {
  return (
    <div className='ws-splash-screen'>
      <Spin />
    </div>
  );
};

const SuspensedView = ({ children }: any) => {
  return <Suspense fallback={<LoadingView />}>{children}</Suspense>;
};

export default SuspensedView;
