export const PROJECT_USERS_GET_REQUEST = 'PROJECT_USERS_GET_REQUEST';
export const PROJECT_USERS_GET_SUCCESS = 'PROJECT_USERS_GET_SUCCESS';
export const PROJECT_USERS_GET_FAILURE = 'PROJECT_USERS_GET_FAILURE';

export const PROJECT_TASKS_GET_REQUEST = 'PROJECT_TASKS_GET_REQUEST';
export const PROJECT_TASKS_GET_SUCCESS = 'PROJECT_TASKS_GET_SUCCESS';
export const PROJECT_TASKS_GET_FAILURE = 'PROJECT_TASKS_GET_FAILURE';

export const SELECTED_PROJECT_GET_REQUEST = 'SELECTED_PROJECT_GET_REQUEST';
export const SELECTED_PROJECT_GET_SUCCESS = 'SELECTED_PROJECT_GET_SUCCESS';
export const SELECTED_PROJECT_GET_FAILURE = 'SELECTED_PROJECT_GET_FAILURE';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';

export const PROJECT_IMPORT_REQUEST = 'PROJECT_IMPORT_REQUEST';
export const PROJECT_IMPORT_SUCCESS = 'PROJECT_IMPORT_SUCCESS';
export const PROJECT_IMPORT_FAILURE = 'PROJECT_IMPORT_FAILURE';

export const ADD_IMPORT_TASK = 'ADD_IMPORT_TASK';
export const UPDATE_IMPORT_PROGRESS = 'UPDATE_IMPORT_PROGRESS';
export const REMOVE_IMPORT_TASK = 'REMOVE_IMPORT_TASK';

export const PROJECT_LOGO_UPDATE_REQUEST = 'PROJECT_LOGO_UPDATE_REQUEST';
export const PROJECT_LOGO_UPDATE_SUCCESS = 'PROJECT_LOGO_UPDATE_SUCCESS';
export const PROJECT_LOGO_UPDATE_FAILURE = 'PROJECT_LOGO_UPDATE_FAILURE';

export const PROJECT_LOGO_GET_REQUEST = 'PROJECT_LOGO_GET_REQUEST';
export const PROJECT_LOGO_GET_SUCCESS = 'PROJECT_LOGO_GET_SUCCESS';
export const PROJECT_LOGO_GET_FAILURE = 'PROJECT_LOGO_GET_FAILURE';

interface ProjectLogoUpdateRequestAction {
    type: typeof PROJECT_LOGO_UPDATE_REQUEST;
    payload: {};
  }
  
  interface ProjectLogoUpdateSuccessAction {
    type: typeof PROJECT_LOGO_UPDATE_SUCCESS;
    payload: any;
  }
  
  interface ProjectLogoUpdateFailureAction {
    type: typeof PROJECT_LOGO_UPDATE_FAILURE;
    payload: {
      error: string;
    };
  }

  interface ProjectLogoGetRequestAction {
    type: typeof PROJECT_LOGO_GET_REQUEST;
    payload: {};
  }
  
  interface ProjectLogoGetSuccessAction {
    type: typeof PROJECT_LOGO_GET_SUCCESS;
    payload: any;
  }
  
  interface ProjectLogoGetFailureAction {
    type: typeof PROJECT_LOGO_GET_FAILURE;
    payload: {
      error: string;
    };
  }
  
interface ProjectUsersGetRequestAction {
    type: typeof PROJECT_USERS_GET_REQUEST;
    payload: {};
}

interface ProjectUsersGetSuccessAction {
    type: typeof PROJECT_USERS_GET_SUCCESS;
    payload: any;
}

interface ProjectUsersGetFailureAction {
    type: typeof PROJECT_USERS_GET_FAILURE;
    payload: {
        error: string;
    };
}

interface ProjectTasksGetRequestAction {
    type: typeof PROJECT_TASKS_GET_REQUEST;
    payload: {};
}

interface ProjectTasksGetSuccessAction {
    type: typeof PROJECT_TASKS_GET_SUCCESS;
    payload: any;
}

interface ProjectTasksGetFailureAction {
    type: typeof PROJECT_TASKS_GET_FAILURE;
    payload: {
        error: string;
    };
}

interface SelectedProjectGetRequestAction {
    type: typeof SELECTED_PROJECT_GET_REQUEST;
    payload: {};
}

interface SelectedProjectGetSuccessAction {
    type: typeof SELECTED_PROJECT_GET_SUCCESS;
    payload: any;
}

interface SelectedProjectGetFailureAction {
    type: typeof SELECTED_PROJECT_GET_FAILURE;
    payload: {
        error: string;
    };
}

interface UpdateTaskRequestAction {
    type: typeof UPDATE_TASK_REQUEST;
    payload: {};
}

interface UpdateTaskSuccessAction {
    type: typeof UPDATE_TASK_SUCCESS;
    payload: any;
}

interface UpdateTaskFailureAction {
    type: typeof UPDATE_TASK_FAILURE;
    payload: {
        error: string;
    };
}

interface GetTaskRequestAction {
    type: typeof GET_TASK_REQUEST;
    payload: {};
}

interface GetTaskSuccessAction {
    type: typeof GET_TASK_SUCCESS;
    payload: any;
}

interface GetTaskFailureAction {
    type: typeof GET_TASK_FAILURE;
    payload: {
        error: string;
    };
}

interface ProjectImportRequestAction {
    type: typeof PROJECT_IMPORT_REQUEST;
    payload: {};
  }
  
  interface ProjectImportSuccessAction {
    type: typeof PROJECT_IMPORT_SUCCESS;
    payload: any;
  }
  
  interface ProjectImportFailureAction {
    type: typeof PROJECT_IMPORT_FAILURE;
    payload: {
      error: string;
    };
  }
  
  interface ProjectImportAddTaskAction {
    type: typeof ADD_IMPORT_TASK;
    payload: any;
  }
  
  interface ProjectImportUpdateProgressAction {
    type: typeof UPDATE_IMPORT_PROGRESS;
    payload: any;
  }
  
  interface ProjectImportRemoveTaskAction {
    type: typeof REMOVE_IMPORT_TASK;
    payload: any;
  }
  


export type ProjectAction =
    | ProjectUsersGetRequestAction
    | ProjectUsersGetSuccessAction
    | ProjectUsersGetFailureAction
    | ProjectTasksGetRequestAction
    | ProjectTasksGetSuccessAction
    | ProjectTasksGetFailureAction
    | SelectedProjectGetRequestAction
    | SelectedProjectGetSuccessAction
    | SelectedProjectGetFailureAction
    | UpdateTaskRequestAction
    | UpdateTaskSuccessAction
    | UpdateTaskFailureAction
    | GetTaskRequestAction
    | GetTaskSuccessAction
    | GetTaskFailureAction
    | ProjectImportRequestAction
    | ProjectImportSuccessAction
    | ProjectImportFailureAction
    | ProjectImportAddTaskAction
    | ProjectImportUpdateProgressAction
    | ProjectImportRemoveTaskAction
    | ProjectLogoUpdateRequestAction
    | ProjectLogoUpdateSuccessAction
    | ProjectLogoUpdateFailureAction
    | ProjectLogoGetRequestAction
    | ProjectLogoGetSuccessAction
    | ProjectLogoGetFailureAction;


    