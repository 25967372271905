import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

const workTimeState = (state: RootState) => state.report.worktime;
const reportExportState = (state: RootState) => state.report.exportReport;
const reportDownloadState = (state: RootState) => state.report.downloadTasks;
const userAssignedProjectsState = (state: RootState) => state.report.userAssignedProjects;
const projectDetailsByProjectIdState = (state: RootState) => state.report.projectDetailsByProjectId;
const userGroupsState = (state: RootState) => state.report.userGroups;
const bandwidthState = (state: RootState) => state.report.bandwidth;
const serviceInfoState = (state: RootState) => state.report.serviceInfo;
const hardwareState = (state:RootState) => state.report.hardware
const attendanceState = (state:RootState) => state.report.attendance
const geoLocationState = (state:RootState) => state.report.geoLocation


export const getWorkTimeData = createSelector(workTimeState, (state) => state.data);
export const getWorkTimeLoading = createSelector(workTimeState, (state) => state.loading);
export const getWorkTimeError = createSelector(workTimeState, (state) => state.error);
export const getWorkTimeTotal = createSelector(workTimeState, (state) => state.total);

export const getReportExportData = createSelector(reportExportState, (state) => state.data);
export const getReportExportLoading = createSelector(reportExportState, (state) => state.loading);
export const getReportExportError = createSelector(reportExportState, (state) => state.error);
export const getDownloadingReports = createSelector(reportDownloadState, (state) => state);


export const getUserAssignedProjectsData = createSelector(userAssignedProjectsState, (state) => state.data);
export const getUserAssignedProjectsLoading = createSelector(userAssignedProjectsState, (state) => state.loading);
export const getUserAssignedProjectsError = createSelector(userAssignedProjectsState, (state) => state.error);

export const getProjectDetailsByProjectIdData = createSelector(projectDetailsByProjectIdState, (state) => state.data);
export const getProjectDetailsByProjectIdLoading = createSelector(projectDetailsByProjectIdState, (state) => state.loading);
export const getProjectDetailsByProjectIdError = createSelector(projectDetailsByProjectIdState, (state) => state.error);

export const getUserGroupsData = createSelector(userGroupsState, (state) => state.data);
export const getUserGroupsLoading = createSelector(userGroupsState, (state) => state.loading);
export const getUserGroupsError = createSelector(userGroupsState, (state) => state.error);

export const getBandwidthData = createSelector(bandwidthState, (state) => state.data);
export const getBandwidthLoading = createSelector(bandwidthState, (state) => state.loading);
export const getBandwidthError = createSelector(bandwidthState, (state) => state.error);
export const getBandwidthTotal = createSelector(bandwidthState, (state) => state.total);

export const getServiceInfoData = createSelector(serviceInfoState, (state) => state.data);
export const getServiceInfoLoading = createSelector(serviceInfoState, (state) => state.loading);
export const getServiceInfoError = createSelector(serviceInfoState, (state) => state.error);

export const getHardwareData = createSelector(hardwareState, (state) => state.data);
export const getHardwareLoading = createSelector(hardwareState, (state) => state.loading);
export const getHardwareError = createSelector(hardwareState, (state) => state.error);
export const getHardwareTotal = createSelector(hardwareState, (state) => state.total);

export const getAttendanceData = createSelector(attendanceState, (state) => state.data);
export const getAttendanceLoading = createSelector(attendanceState, (state) => state.loading);
export const getAttendanceError = createSelector(attendanceState, (state) => state.error);
export const getAttendanceTotal = createSelector(attendanceState, (state) => state.total);

export const getGeoLocationData = createSelector(geoLocationState, (state) => state.data);
export const getGeoLocationLoading = createSelector(geoLocationState, (state) => state.loading);
export const getGeoLocationError = createSelector(geoLocationState, (state) => state.error);
export const getGeoLocationTotal = createSelector(geoLocationState, (state) => state.total);