import {
  CLIENT_CREATE_FAILURE,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_GET_FAILURE,
  CLIENT_GET_REQUEST,
  CLIENT_GET_SUCCESS,
  CLIENT_IMPORT_FAILURE,
  CLIENT_IMPORT_REQUEST,
  CLIENT_IMPORT_SUCCESS,
  CLIENT_UPDATE_FAILURE,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  ClientAction,
  REMOVE_IMPORT_TASK,
  UPDATE_IMPORT_PROGRESS,
} from 'store/actionTypes/clientActionTypes';
import { ADD_IMPORT_TASK } from 'store/actionTypes/projectActionTypes';

import type { Client } from 'types/models/client';


type Clients = {
  data: Client[];
  loading: boolean;
  creating: boolean;
  updating: boolean;
  error: string | null;
  total: number;
};

export interface ImportTask {
  queueId: string;
  fileName: string;
  progress: number;
  error: string | null;
}

export interface ImportClient {
  data: any;
  loading: boolean;
  error: string | null;
}

interface ClientState {
  clients: Clients;
  importTasks: ImportTask[];
  importClient: ImportClient;
}

const initialState: ClientState = {
  clients: {
    data: [],
    loading: false,
    creating: false,
    updating: false,
    total: 0,
    error: null,
  },
  importTasks: [],
  importClient: {
    data: {},
    loading: false,
    error: '',
  },
};

const clientReducer = (state = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    //get
    case CLIENT_GET_REQUEST:
      return {
        ...state,
        clients: { ...state.clients, loading: true },
      };
    case CLIENT_GET_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          data: action.payload?.list || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case CLIENT_GET_FAILURE:
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          error: action.payload.error,
        },
      };
    // create
    case CLIENT_CREATE_REQUEST:
      return {
        ...state,
        clients: {
          ...state.clients,
          creating: true,
        },
      };
    case CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          data: [...state.clients?.data, action.payload],
          creating: false,
        },
      };
    case CLIENT_CREATE_FAILURE:
      return {
        ...state,
        clients: {
          ...state.clients,
          creating: false,
          error: action.payload.error,
        },
      };
    // update
    case CLIENT_UPDATE_REQUEST:
      return {
        ...state,
        clients: { ...state.clients, updating: true },
      };
    case CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          data: state.clients.data?.map((ele) =>
            ele.id === action.payload?.id ? { ...ele, ...action.payload } : ele
          ),
          updating: false,
          error: null,
        },
      };
    case CLIENT_UPDATE_FAILURE:
      return {
        ...state,
        clients: {
          ...state.clients,
          updating: false,
          error: action.payload.error,
        },
      };
    //import 
    case CLIENT_IMPORT_REQUEST:
      return {
        ...state,
        importClient: { ...state.importClient, loading: true },
      };
    case CLIENT_IMPORT_SUCCESS:
      return {
        ...state,
        importClient: {
          ...state.importClient,
          data: action.payload?.importClient || {},
          loading: false,
        },
      };
    case CLIENT_IMPORT_FAILURE:
      return {
        ...state,
        importClient: {
          ...state.importClient,
          loading: false,
          error: action.payload.error,
        },
      };
    case ADD_IMPORT_TASK:
      return {
        ...state,
        importTasks: [...state.importTasks, action.payload.task],
      };
    case UPDATE_IMPORT_PROGRESS:
      const updatedTasks = state.importTasks.map((task) =>
        task.queueId === action.payload.queueId
          ? { ...task, fileName: action.payload.fileName, progress: action.payload.progress }
          : task
      );
      return {
        ...state,
        importTasks: updatedTasks,
      };
    case REMOVE_IMPORT_TASK:
      const filteredTasks = state.importTasks.filter(
        (task) => task.queueId !== action.payload.queueId
      );
      return {
        ...state,
        importTasks: filteredTasks,
      };
    default:
      return state;
  }
};

export default clientReducer;
