export const TEAMS_PULSE_GET_REQUEST = 'TEAMS_PULSE_GET_REQUEST';
export const TEAMS_PULSE_GET_SUCCESS = 'TEAMS_PULSE_GET_SUCCESS';
export const TEAMS_PULSE_GET_FAILURE = 'TEAMS_PULSE_GET_FAILURE';

export const PRODUCTIVITY_GET_REQUEST = 'PRODUCTIVITY_GET_REQUEST';
export const PRODUCTIVITY_GET_SUCCESS = 'PRODUCTIVITY_GET_SUCCESS';
export const PRODUCTIVITY_GET_FAILURE = 'PRODUCTIVITY_GET_FAILURE';

export const ACTIVITY_SUMMARY_GET_REQUEST = 'ACTIVITY_SUMMARY_GET_REQUEST';
export const ACTIVITY_SUMMARY_GET_SUCCESS = 'ACTIVITY_SUMMARY_GET_SUCCESS';
export const ACTIVITY_SUMMARY_GET_FAILURE = 'ACTIVITY_SUMMARY_GET_FAILURE';

export const TOP_PROJECTS_GET_REQUEST = 'TOP_PROJECTS_GET_REQUEST';
export const TOP_PROJECTS_GET_SUCCESS = 'TOP_PROJECTS_GET_SUCCESS';
export const TOP_PROJECTS_GET_FAILURE = 'TOP_PROJECTS_GET_FAILURE';

export const USER_LOCATIONS_GET_REQUEST = 'USER_LOCATIONS_GET_REQUEST';
export const USER_LOCATIONS_GET_SUCCESS = 'USER_LOCATIONS_GET_SUCCESS';
export const USER_LOCATIONS_GET_FAILURE = 'USER_LOCATIONS_GET_FAILURE';

export const USER_LOCATIONS_UPDATE_REQUEST = 'USER_LOCATIONS_UPDATE_REQUEST';
export const USER_LOCATIONS_UPDATE_SUCCESS = 'USER_LOCATIONS_UPDATE_SUCCESS';
export const USER_LOCATIONS_UPDATE_FAILURE = 'USER_LOCATIONS_UPDATE_FAILURE';

interface TeamsPulseGetRequestAction {
  type: typeof TEAMS_PULSE_GET_REQUEST;
  payload: {};
}

interface TeamsPulseGetSuccessAction {
  type: typeof TEAMS_PULSE_GET_SUCCESS;
  payload: any;
}

interface TeamsPulseGetFailureAction {
  type: typeof TEAMS_PULSE_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ProductivityGetRequestAction {
  type: typeof PRODUCTIVITY_GET_REQUEST;
  payload: {};
}

interface ProductivityGetSuccessAction {
  type: typeof PRODUCTIVITY_GET_SUCCESS;
  payload: any;
}

interface ProductivityGetFailureAction {
  type: typeof PRODUCTIVITY_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ActivitySummaryGetRequestAction {
  type: typeof ACTIVITY_SUMMARY_GET_REQUEST;
  payload: {};
}

interface ActivitySummaryGetSuccessAction {
  type: typeof ACTIVITY_SUMMARY_GET_SUCCESS;
  payload: any;
}

interface ActivitySummaryGetFailureAction {
  type: typeof ACTIVITY_SUMMARY_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface TopProjectsGetRequestAction {
  type: typeof TOP_PROJECTS_GET_REQUEST;
  payload: {};
}

interface TopProjectsGetSuccessAction {
  type: typeof TOP_PROJECTS_GET_SUCCESS;
  payload: any;
}

interface TopProjectsGetFailureAction {
  type: typeof TOP_PROJECTS_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface GeoLocationsGetRequestAction {
  type: typeof USER_LOCATIONS_GET_REQUEST;
  payload: {};
}

interface GeoLocationsGetSuccessAction {
  type: typeof USER_LOCATIONS_GET_SUCCESS;
  payload: any;
}

interface GeoLocationsGetFailureAction {
  type: typeof USER_LOCATIONS_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface GeoLocationsUpdateRequestAction {
  type: typeof USER_LOCATIONS_UPDATE_REQUEST;
  payload: {};
}

interface GeoLocationsUpdateSuccessAction {
  type: typeof USER_LOCATIONS_UPDATE_SUCCESS;
  payload: any;
}

interface GeoLocationsUpdateFailureAction {
  type: typeof USER_LOCATIONS_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

export type ReportAction =
  | TeamsPulseGetRequestAction
  | TeamsPulseGetSuccessAction
  | TeamsPulseGetFailureAction
  | ProductivityGetRequestAction
  | ProductivityGetSuccessAction
  | ProductivityGetFailureAction
  | ActivitySummaryGetRequestAction
  | ActivitySummaryGetSuccessAction
  | ActivitySummaryGetFailureAction
  | TopProjectsGetRequestAction
  | TopProjectsGetSuccessAction
  | TopProjectsGetFailureAction
  | GeoLocationsGetRequestAction
  | GeoLocationsGetSuccessAction
  | GeoLocationsGetFailureAction
  | GeoLocationsUpdateRequestAction
  | GeoLocationsUpdateSuccessAction
  | GeoLocationsUpdateFailureAction;
