import { combineReducers } from 'redux';
import authReducer from './authReducer';
import sidebarSlice from './sidebar/sidebarSlice';
import paymentReducer from './paymentReducer';
import companyReducer from './companyReducer';
import userReducer from './userReducer';
import reportReducer from './reportReducer';
import projectReducer from './projectReducer';
import workScheduleReducer from './workScheduleReducer';
import clientReducer from './clientReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  payment: paymentReducer,
  sidebar: sidebarSlice,
  company: companyReducer,
  client: clientReducer,
  user: userReducer,
  report: reportReducer,
  project: projectReducer,
  workschedule: workScheduleReducer,
  dashboard: dashboardReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
