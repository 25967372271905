import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import {
  GET_USER_ONLINE_FAILURE,
  GET_USER_ONLINE_REQUEST,
  GET_USER_ONLINE_SUCCESS,
  SET_USER_ONLINE_FAILURE,
  SET_USER_ONLINE_REQUEST,
  SET_USER_ONLINE_SUCCESS,
  USER_GET_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  UserAction,
} from 'store/actionTypes/userActionTypes';
import {
  getCompanyUsers as fetchCompanyUsers,
  fetchUserOnlineStatus,
  // fetchUserOnlineStatus as fetchUserOnlineStatusRequest,
} from 'services/api/user';

export const getCompanyUsers = (
  companyId: string,
  queryOptions: {}
): ThunkAction<void, RootState, null, UserAction> => {
  return async (dispatch) => {
    dispatch({ type: USER_GET_REQUEST, payload: [] });
    try {
      const response = await fetchCompanyUsers(companyId, queryOptions);
      const users = response.data;

      dispatch({ type: USER_GET_SUCCESS, payload: users });
    } catch (error: any) {
      dispatch({ type: USER_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const getUsersOnlineStatus = (
  companyId: string,
  queryOptions: {}
): ThunkAction<void, RootState, null, UserAction> => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_ONLINE_REQUEST, payload: [] });
    try {
      const response = await fetchUserOnlineStatus(companyId, queryOptions);
      const users = response.data;

      dispatch({ type: GET_USER_ONLINE_SUCCESS, payload: users });
    } catch (error: any) {
      dispatch({ type: GET_USER_ONLINE_FAILURE, payload: { error: error.message } });
    }
  };
};

export const setUsersOnlineStatus = (
  userId: string,
  status: 'idle' | 'online' | 'offline',
  lastOnlineAt: number,
): ThunkAction<void, RootState, null, UserAction> => {
  return async (dispatch) => {
    dispatch({ type: SET_USER_ONLINE_REQUEST, payload: [] });
    try {
      dispatch({ type: SET_USER_ONLINE_SUCCESS, payload: { userId, lastOnlineAt, status } });
    } catch (error: any) {
      console.log('ERRRRRR', error);
      dispatch({ type: SET_USER_ONLINE_FAILURE, payload: { error: error.message } });
    }
  };
};
