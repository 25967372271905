import { GET, POST, PUT, request } from '../adapter';

export const getTeamsPulseReport = async (params: any, filterIds: string[]) => {
  return await request(`dashboard/teams-pulse`, POST, { filterIds }, params);
};

export const getProductivityReport = async (params: any, filterIds: string[]) => {
  return await request(`dashboard/productivity`, POST, { filterIds } ?? {}, params ?? {});
};

export const getActivitySummaryReport = async (params: any, filterIds: string[]) => {
  return await request(`dashboard/activity-sumary`, POST, { filterIds } ?? {}, params ?? {});
};

export const getTopProjectReport = async (params: any, filterIds: string[]) => {
  return await request(`dashboard/top-projects`, POST, { filterIds } ?? {}, params ?? {});
};

export const getUserLocations = async (params: any, filterIds: string[]) => {
  return await request(`dashboard/locations`, POST, { filterIds } ?? {}, params ?? {});
};
