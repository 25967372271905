import {
  ReportAction,
  TEAMS_PULSE_GET_FAILURE,
  TEAMS_PULSE_GET_REQUEST,
  TEAMS_PULSE_GET_SUCCESS,
  PRODUCTIVITY_GET_REQUEST,
  PRODUCTIVITY_GET_SUCCESS,
  PRODUCTIVITY_GET_FAILURE,
  ACTIVITY_SUMMARY_GET_REQUEST,
  ACTIVITY_SUMMARY_GET_SUCCESS,
  ACTIVITY_SUMMARY_GET_FAILURE,
  TOP_PROJECTS_GET_FAILURE,
  TOP_PROJECTS_GET_SUCCESS,
  TOP_PROJECTS_GET_REQUEST,
  USER_LOCATIONS_GET_REQUEST,
  USER_LOCATIONS_GET_SUCCESS,
  USER_LOCATIONS_GET_FAILURE,
  USER_LOCATIONS_UPDATE_REQUEST,
  USER_LOCATIONS_UPDATE_SUCCESS,
  USER_LOCATIONS_UPDATE_FAILURE,
} from 'store/actionTypes/dashboardActionTypes';
import {
  ProductivityData,
  TeamsPulse,
  ActivitySummary,
  TopProjects,
  userLocation,
} from 'types/models/dashboard';

export interface TeamsPulseState {
  data: TeamsPulse[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface ProductivityState {
  data: ProductivityData[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface ActivitySummaryState {
  data: ActivitySummary[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface TopProjectsState {
  data: TopProjects[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface userLocationsState {
  data: userLocation[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface ReportState {
  teamsPulse: TeamsPulseState;
  productivity: ProductivityState;
  activitySummary: ActivitySummaryState;
  topProjects: TopProjectsState;
  userLocations: userLocationsState;
}

const initialState: ReportState = {
  teamsPulse: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  productivity: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  activitySummary: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  topProjects: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  userLocations: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
};

const dashboardReducer = (state = initialState, action: ReportAction): ReportState => {
  switch (action.type) {
    case TEAMS_PULSE_GET_REQUEST:
      return {
        ...state,
        teamsPulse: { ...state.teamsPulse, loading: true },
      };
    case TEAMS_PULSE_GET_SUCCESS:
      return {
        ...state,
        teamsPulse: {
          ...state.teamsPulse,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case TEAMS_PULSE_GET_FAILURE:
      return {
        ...state,
        teamsPulse: {
          ...state.teamsPulse,
          loading: false,
          error: action.payload.error,
        },
      };

    case PRODUCTIVITY_GET_REQUEST:
      return {
        ...state,
        productivity: { ...state.productivity, loading: true },
      };
    case PRODUCTIVITY_GET_SUCCESS:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case PRODUCTIVITY_GET_FAILURE:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          loading: false,
          error: action.payload.error,
        },
      };

    case ACTIVITY_SUMMARY_GET_REQUEST:
      return {
        ...state,
        activitySummary: { ...state.activitySummary, loading: true },
      };
    case ACTIVITY_SUMMARY_GET_SUCCESS:
      return {
        ...state,
        activitySummary: {
          ...state.activitySummary,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case ACTIVITY_SUMMARY_GET_FAILURE:
      return {
        ...state,
        activitySummary: {
          ...state.activitySummary,
          loading: false,
          error: action.payload.error,
        },
      };

    case TOP_PROJECTS_GET_REQUEST:
      return {
        ...state,
        topProjects: { ...state.topProjects, loading: true },
      };
    case TOP_PROJECTS_GET_SUCCESS:
      return {
        ...state,
        topProjects: {
          ...state.topProjects,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case TOP_PROJECTS_GET_FAILURE:
      return {
        ...state,
        topProjects: {
          ...state.topProjects,
          loading: false,
          error: action.payload.error,
        },
      };

    case USER_LOCATIONS_GET_REQUEST:
      return {
        ...state,
        userLocations: { ...state.userLocations, loading: true },
      };
    case USER_LOCATIONS_GET_SUCCESS:
      return {
        ...state,
        userLocations: {
          ...state.userLocations,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case USER_LOCATIONS_GET_FAILURE:
      return {
        ...state,
        userLocations: {
          ...state.userLocations,
          loading: false,
          error: action.payload.error,
        },
      };

    case USER_LOCATIONS_UPDATE_REQUEST:
      return {
        ...state,
        userLocations: { ...state.userLocations, loading: true },
      };
    case USER_LOCATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        userLocations: {
          ...state.userLocations,
          data: state.userLocations?.data?.map((ele) =>
            ele?.userId === action?.payload?.userId ? { ...ele, ...action?.payload } : ele
          ),
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case USER_LOCATIONS_UPDATE_FAILURE:
      return {
        ...state,
        userLocations: {
          ...state.userLocations,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
