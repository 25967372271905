import { Company } from 'types/models/company';

export const COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_FAILURE = 'COMPANY_GET_FAILURE';

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE';

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAILURE = 'COMPANY_UPDATE_FAILURE';

interface CompanyGetRequestAction {
  type: typeof COMPANY_GET_REQUEST;
  payload: {};
}

interface CompanyGetSuccessAction {
  type: typeof COMPANY_GET_SUCCESS;
  payload: Company;
}

interface CompanyGetFailureAction {
  type: typeof COMPANY_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface CompanyCreateRequestAction {
  type: typeof COMPANY_CREATE_REQUEST;
  payload: {};
}

interface CompanyCreateSuccessAction {
  type: typeof COMPANY_CREATE_SUCCESS;
  payload: Company;
}

interface CompanyCreateFailureAction {
  type: typeof COMPANY_CREATE_FAILURE;
  payload: {
    error: string;
  };
}

interface CompanyUpdateRequestAction {
  type: typeof COMPANY_UPDATE_REQUEST;
  payload: {};
}

interface CompanyUpdateSuccessAction {
  type: typeof COMPANY_UPDATE_SUCCESS;
  payload: Company;
}

interface CompanyUpdateFailureAction {
  type: typeof COMPANY_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

export type CompanyAction =
  | CompanyGetRequestAction
  | CompanyGetSuccessAction
  | CompanyGetFailureAction
  | CompanyCreateRequestAction
  | CompanyCreateSuccessAction
  | CompanyCreateFailureAction
  | CompanyUpdateRequestAction
  | CompanyUpdateSuccessAction
  | CompanyUpdateFailureAction;
