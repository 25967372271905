import React, { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getDownloadingReports } from 'store/selectors/reportSelector';

interface DownloadStatusBarProps {}
const key = `report-download`;

const DownloadStatusBar: React.FC<DownloadStatusBarProps> = () => {
  const [api, contextHolder] = notification.useNotification();

  const data = useSelector((state: RootState) => getDownloadingReports(state));

  console.log(data);

  const handleClose = useCallback(() => {
    api.destroy(key);
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.'
    );
  }, [api]);

  const openNotification = useCallback(() => {
    const btn = (
      <div className='btn' onClick={handleClose}>
        Close
      </div>
    );
    api.open({
      message: 'Process Reports',
      description: (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr>
                  <th className='p-0 min-w-200px'></th>
                  <th className='p-0 min-w-20px'></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((element) => (
                  <tr>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
                        {element?.fileName} 
                      </div>
                      <span className='text-muted fw-semibold d-block fs-7'>
                      <div className='progress h-6px w-100'>
                          <div
                            className='progress-bar bg-primary'
                            role='progressbar'
                            style={{ width: `${element?.progress}%` }}
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                        </span>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-20 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>{element?.progress}%</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ),
      btn,
      key,
      duration: null,
    });
  }, [handleClose, api, data]);

  useEffect(() => {
    openNotification();
    if (data.length > 0) {
    } else {
      handleClose();
    }
  }, [data, openNotification, handleClose]);

  return <>{contextHolder}</>;
};
export default DownloadStatusBar;
