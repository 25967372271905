import type { AxiosResponse, Method } from 'axios';
import axios from 'axios';
import { isArray, capitalize, isObject, uniqueId } from 'lodash';
import { notification } from 'antd';
import { SESSION_KEY, API_URL, ROUTE_BASE_NAME } from '../constants';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const PATCH = 'patch';

export interface ApiResponse extends AxiosResponse {}

const codeMessage: Record<number, string> = {
  200: 'The server successfully returned the requested data. ',
  201: 'Create or modify data successfully. ',
  202: 'A request has entered the background queue (asynchronous task). ',
  204: 'Delete data successfully. ',
  400: 'There is an error in the request sent, and the server did not create or modify data. ',
  401: 'Unathorized! Session expired please relogin to continue',
  403: 'The user is authorized, but access is forbidden. ',
  404: 'The request sent is for a record that does not exist, and the server is not operating. ',
  406: 'The requested format is not available. ',
  410: 'The requested resource has been permanently deleted and will no longer be available. ',
  422: 'When creating an object, a validation error occurred. ',
  500: 'An error occurred in the server, please check the server. ',
  502: 'Gateway error. ',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway has timed out. ',
};

// @ts-ignore
const baseUrl = `${API_URL}/api/`;
const axiosInstance = axios.create();

// response interceptor to refresh token on receiving token expired error
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(null);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      //eslint no-underscore-dangle
      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${baseUrl}/refresh-token`,
            {},
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
            }
          )
          .then(() => {
            processQueue(null);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            if (err?.response?.status === 401) {
              localStorage.removeItem(SESSION_KEY);
              window.location.href = `${ROUTE_BASE_NAME}/login`;
            }
            processQueue(err);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

const request = async (
  url: string,
  type: Method = GET,
  data: any = {},
  params: any = {},
  onProgress: any = {},
  headers: any = { 'Content-Type': 'application/json' },
  responseType: string = ''
) => {
  const routePath = baseUrl + (baseUrl?.slice(-1) === '/' ? '' : '/') + url;
  // const token = await getAuthToken();
  // TODO : get and set token from session storage
  // headers.Authorization = headers.Authorization ? headers.Authorization : token;
  // const headers: any = { "Content-Type": "application/json" }
  // headers.Authorization = headers.Authorization
  //   ? headers.Authorization
  //   : token;

  headers['origin-app'] = 'web-app';

  const options: any = {
    method: type,
    url: routePath,
    data,
    params,
    headers,
    onUploadProgress: onProgress,
    withCredentials: true,
    responseType,
  };

  const prepareValidationMessage = (validationChain: Record<string, string>[]) => {
    let validationMessage = '';
    validationChain.forEach((validation) => {
      const validationKey = Object.keys(validation)[0];
      validationMessage += `${capitalize(validationKey)}: ${capitalize(validation[validationKey])}`;
    });
    return validationMessage;
  };

  // options.timeout=1000*60*2
  return axiosInstance(options)
    .then((response: AxiosResponse) => {
      const { status, data, statusText } = response;
      return {
        status,
        data: data?.data || {},
        message: data?.message || statusText,
      };
    })
    .catch((error: any) => {
      const { response } = error;
      const originalRequest = error.config;
      if (response && response?.status) {
        const errorText =
          isObject(response?.data) && 'message' in response?.data
            ? isArray(response?.data?.message)
              ? prepareValidationMessage(response?.data?.message)
              : response?.data?.message
            : codeMessage[response?.status];
        if (error.response?.status === 401 && originalRequest.url === `/refresh-token`) {
          window.location.href = `${ROUTE_BASE_NAME}/login`;
          localStorage.removeItem(SESSION_KEY);
          return { data: {} };
        }
        // if (response.status === 401) {
        // logout();
        // window.location.href = '/';
        //   return { data: {} };
        // }
        if (response?.status === 403) {
          // Direct to permission denied page
          notification.error({
            message: `Access Denied!`,
            key: `Access Denied!`,
            description: errorText,
          });
        } else if (response?.status === 401) {
          // Direct to permission denied page
          window.location.href = `${ROUTE_BASE_NAME}/401`;
          notification.error({
            message: `Unauthorized!`,
            key: `Unauthorized!`,
            description: errorText,
          });
        } else if ([404, 400].includes(response?.status)) {
          notification.error({
            message: ``,
            key: uniqueId(),
            description: errorText,
          });
        } else {
          notification.error({
            message: ``,
            key: uniqueId(),
            description: errorText,
          });
        }
      } else if (error?.message === 'Network Error') {
        // This is a network error.
        notification.error({
          message: `Network Error`,
          key: `Network Error`,
          description:
            'Something is wrong with your network connection, please make sure you are connected to the internet!',
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        notification.error({
          message: `Error Occuerd`,
          key: `Network Error`,
          description: 'An error occurred in the server, please check the server',
        });
      }
      throw error;
    });
};

export { request, GET, POST, PUT, DELETE, PATCH };
