import { useEffect, useState } from 'react';

import { request, POST } from '../services/adapter';

import { pick, isArray, isEmpty, isNull, isUndefined } from 'lodash';

import { KeyValueObject } from '../types/common';

import { SESSION_KEY, USER_ROLES } from 'constants/index';

const TOC_KEY: string = 'TOC'; // local-storage key for save session
const SELECTED_USER_GROUP: string = 'selected-user-groups'; //localstorage key for selected user/groups

const RESPONSE_LOGIN_FAIL = 'Login failed';

// const RESPONSE_TOC_FAIL = 'TOC failed';

export type RoleTypes = keyof typeof USER_ROLES;

export interface IAuthParams {
  email: string;
  password: string;
  captchaValue?: string;
}

export interface ssoLoginParams {
  type: string;
  token: string;
  email?: string;
  captchaValue?: string;
}

interface ILoginResponseData {
  token: string;
  user: KeyValueObject;
}

// interface ILoginResponse {
//   error?: boolean;
//   message?: string;
//   data: ILoginResponseData;
// }

export interface ICompany {
  id: string;
  name: string;
  email: string;
  configurationId: string | null;
  timezone: string;
  timeFormat: '12_HOURS' | '24_HOURS';
}

export interface IUserSession {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isClientActive: boolean;
  companies?: Array<ICompany>;
  currentCompany: ICompany | undefined;
  token: string;
  role: {
    id: string;
    name: RoleTypes;
    permissions: any;
    description: string;
  } | null;
  profileImage: string;
}

export interface IUserTOC {
  isTOCAccepted: boolean;
}

export let authenticated = false; // Current authentication status of the logged in user

export let tocAccepted = false; // Current user TOC status

let userSession: IUserSession = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  isClientActive: false,
  currentCompany: undefined,
  token: '',
  role: null,
  profileImage: '',
};

let userTOC: IUserTOC = {
  isTOCAccepted: false,
};

export let userRoleType: string = USER_ROLES['USER']; //  Role type of the logged in user

/**
 * Login authentication function
 * @param authParams - email, password contained object
 */
// export const login = async (authParams: IAuthParams) => {
//   try {
//     const response: ILoginResponse = await request(`login`, POST, authParams);
//     if (!response.error) {
//       if (
//         response.data.user &&
//         response.data.user.isTwoFactorAuthenticationEnabled
//       ) {
//         return {
//           ...response.data.user,
//           isAuthenticated: false,
//           token: response.data.token,
//         };
//       }
//       await createSession(response.data);
//       return response.data.user;
//     } else {
//       return response;
//     }
//   } catch (error: any) {
//     throw Error(RESPONSE_LOGIN_FAIL);
//   }
// };

/**
 * Login with Google or  Linkedin function
 */
export const ssoLogin = async (authParams: ssoLoginParams) => {
  try {
    const response: any = await request(`sso-login`, POST, authParams);

    if (!response.error) {
      if (response.data.user && response.data.user.isTwoFactorAuthenticationEnabled) {
        return {
          ...response.data.user,
          isAuthenticated: false,
          token: response.data.token,
        };
      }

      await createSession(response.data);
      return response.data;
    } else {
      return response;
    }
  } catch (error: any) {
    throw Error(RESPONSE_LOGIN_FAIL);
  }
};

/**
 * two fac authentication function
 */
// export const verifyTwoAuthToken = async (authParams: any) => {
//   try {
//     const headers = { Authorization: `Bearer ${authParams.token}` };
//     const response: ILoginResponse = await request(
//       `verify-otp`,
//       POST,
//       authParams,
//       {},
//       headers
//     );
//     if (!response.error) {
//       await createSession(response.data);
//       return response.data.user;
//     } else {
//       throw Error(RESPONSE_LOGIN_FAIL);
//     }
//   } catch (error: any) {
//     throw Error(error.message);
//   }
// };

/**
 * resend OTP code
 */
export const resendOTPCode = async (authParams: any) => {
  try {
    const headers = { Authorization: `Bearer ${authParams.token}` };
    const response: any = await request(`resend-otp`, POST, authParams, {}, headers);
    if (!response.error) {
      return {
        ...response.data.user,
        isAuthenticated: false,
        token: response.data.token,
      };
    } else {
      throw Error(RESPONSE_LOGIN_FAIL);
    }
  } catch (error: any) {
    throw Error(error.message);
  }
};

/**
 * Updates the global scope user object with session data.
 * @param session - session object.
 */
const updateUserSession = (session: KeyValueObject) => {
  userSession.id = session && session.id ? session.id : '';
  userSession.email = session && session.email ? session.email : '';
  userSession.firstName = session && session.firstName ? session.firstName : '';
  userSession.lastName = session && session.lastName ? session.lastName : '';
  userSession.isClientActive = session && session.isClientActive ? session.isClientActive : false;
  if (session && isArray(session.companies)) {
    userSession.companies = session.companies;
    userSession.currentCompany = !isEmpty(session.companyId)
      ? session.companies.filter((company: any) => company.id === session.companyId)[0]
      : !isEmpty(session.companies)
      ? session.companies[0]
      : undefined;
  } else {
    userSession.currentCompany = session && session.company ? session.company : undefined;
  }
  userSession.token = session && session.token ? session.token : '';
  userSession.role = session && session.role ? session.role : '';
  userSession.profileImage = session && session.profileImage ? session.profileImage : '';
};

/**
 * Erase all keys of global scope user object
 */
const deleteUserSessionKeys = () => {
  for (const prop of Object.getOwnPropertyNames(userSession)) {
    /* @ts-ignore */
    delete userSession[prop];
  }
};

/**
 * Remove session object localStorage data on logout.
 * @param routerHistory - History<History.PoorMansUnknown> object for routing
 * @param redirect - Redirecting path
 */
export const logout = async (routerHistory?: KeyValueObject, redirect?: string) => {
  try {
    const response: any = await request(`logout`, POST, {});
    if (!response.error) {
      deleteUserSessionKeys();
      localStorage.removeItem(SESSION_KEY);
      localStorage.removeItem(TOC_KEY);
      localStorage.removeItem(SELECTED_USER_GROUP);
      authenticated = false;
      tocAccepted = false;
      return response;
    } else {
      return response;
    }
  } catch (error: any) {
    throw Error('Failed to logout');
  }
};

/**
 * Creates a session object in localstorage.
 * @param responseData - Response Data from authentication
 */
export const createSession = async (responseData: ILoginResponseData) => {
  const { user } = pick(responseData, ['token', 'user']);
  const session = { ...user };
  updateUserSession(session);
  localStorage.setItem(SESSION_KEY, JSON.stringify(userSession));
  authenticated = true;

  userRoleType = String(user.role.name);

  if (
    userRoleType === USER_ROLES['SUPER_ADMIN'] ||
    userRoleType === USER_ROLES['ADMIN'] ||
    userRoleType === USER_ROLES['MANAGER']
  ) {
    // await handleUserTOC(user);
  } else {
    setTOCAccepted(true);
  }
};

/**
 * Checks if token exists in localstorage & updates authenticated flag.
 */
export const checkAuth = () => {
  const session = getSession();
  if (session && !isUndefined(session)) {
    authenticated = session.hasOwnProperty('id') && session.id ? true : false;
  } else {
    authenticated = false;
  }
};

/**
 * Checks if token exists in localstorage & updates authenticated flag.
 */
export const checkTOC = () => {
  const toc = getTOC();
  if (toc && !isUndefined(toc)) {
    tocAccepted = toc.hasOwnProperty('isTOCAccepted') && toc.isTOCAccepted ? true : false;
  } else {
    tocAccepted = false;
  }
};

/**
 * Check & update the use role type
 */
export const checkUserRole = () => {
  userRoleType = userRole();
};

/**
 * Returns the auth JWT token
 * @param isBearerToken - Flag to determine whether to return in "Bearer {TOKEN}" format.
 */
export const getAuthToken = (isBearerToken: boolean = false) => {
  const session = getSession();
  const token = session && session.hasOwnProperty('token') ? session.token : '';
  // if (!isBearerToken) {
  //   return token;
  // }
  return `Bearer ${token}`;
};

/**
 * Returns the user role.
 */
export const userRole = (): string => {
  const session = getSession();
  let ROLE = '';
  if (session && !isUndefined(session)) {
    ROLE = session.role && session.role.name ? session.role.name : USER_ROLES['USER'];
  } else {
    ROLE = USER_ROLES['USER'];
  }
  return ROLE;
};

/**
 * Returns the session object stored in localStorage
 */
export const getSession = (): any => {
  let sessionJson: any = localStorage.getItem(SESSION_KEY);
  if (sessionJson && !isNull(sessionJson)) {
    const session = JSON.parse(sessionJson);
    return session;
  }
  return null;
};

/**
 * Returns the toc object stored in localStorage
 */
export const getTOC = (): any => {
  let tocJson: any = localStorage.getItem(TOC_KEY);
  if (tocJson && !isNull(tocJson)) {
    const toc = JSON.parse(tocJson);
    return toc;
  }
  return null;
};

export const updateSessionKey = (key: keyof IUserSession, value: any) => {
  const sessionObject = getSession();
  /* @ts-ignore */
  sessionObject[`${key}`] = value;
  localStorage.setItem(SESSION_KEY, JSON.stringify(sessionObject));
};

export const updateAssignedCompanies = (newCompany: any) => {
  const session = getSession();
  const companyList = session?.companies ? session.companies : [];
  const replaceIndex = companyList.findIndex((company: any) => company?.id === newCompany?.id);
  if (replaceIndex >= 0) {
    companyList[replaceIndex] = newCompany;
  } else {
    companyList.push(newCompany);
  }
  if (newCompany.id === session?.currentCompany?.id || isUndefined(session?.currentCompany)) {
    updateSessionKey('currentCompany', newCompany);
  }
  updateSessionKey('companies', companyList);
};

/**
 * React hook to handle session from browser storage
 * @param sessionKey - Browser storage key. Default is set to the key `user-session`
 * @returns If there is a session in your browser storage then it will be returned. If there is no session, it will return null.
 *
 * Usage - This hook is useful in scenarios where you need to listen to session changes in functional components
 *
 * import { useSession } from "session/auth";
 *
 * ...
 * <FC>
 *  const { session } = useSession();
 * </FC>
 */
export const useSession = () => {
  const [state, setState] = useState<IUserSession>(getSession);

  const syncState = (event: StorageEvent) => {
    if (event.key === SESSION_KEY) {
      setState(getSession);
    }
  };

  /**
   * Muatate/update session key
   * @param key = Session key to be updated
   * @param value = Updating value
   */
  const updateSessionKey = (key: keyof IUserSession, value: any) => {
    const sessionObject = state;
    /* @ts-ignore */
    sessionObject[`${key}`] = value;
    localStorage.setItem(SESSION_KEY, JSON.stringify(sessionObject));
  };

  useEffect(() => {
    window.addEventListener('storage', syncState);
    return () => {
      window.removeEventListener('storage', syncState);
    };
  }, []);

  return { session: state, updateSessionKey };
};

/**
 * set tocAccepted to True
 */
export const setTOCAccepted = (value: boolean) => {
  tocAccepted = value;
  updateTOCLocalStorage(value);
};

const updateTOCLocalStorage = (value: boolean) => {
  userTOC.isTOCAccepted = value;
  localStorage.setItem(TOC_KEY, JSON.stringify(userTOC));
};

/**
 * get User TOC from API
 */
// const handleUserTOC = async (user: any) => {
//   try {
//     const result: any = await request(`users/${user.id}/term-and-condition-acceptance`, GET);
//     if (!result.error) {
//       if (!isEmpty(result.data) && result.data.status === 'accepted') {
//         setTOCAccepted(true);
//       }
//     } else {
//       throw Error(RESPONSE_TOC_FAIL);
//     }
//   } catch (error: any) {
//     throw Error(error.message);
//   }
// };

export const getSocketTokenFromCookies = () => {
  const name = 'socketws=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};