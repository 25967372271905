export const WORKTIME_GET_REQUEST = 'WORKTIME_GET_REQUEST';
export const WORKTIME_GET_SUCCESS = 'WORKTIME_GET_SUCCESS';
export const WORKTIME_GET_FAILURE = 'WORKTIME_GET_FAILURE';

export const REPORT_EXPORT_REQUEST = 'REPORT_EXPORT_REQUEST';
export const REPORT_EXPORT_SUCCESS = 'REPORT_EXPORT_SUCCESS';
export const REPORT_EXPORT_FAILURE = 'REPORT_EXPORT_FAILURE';

export const ADD_DOWNLOAD_TASK = 'ADD_DOWNLOAD_TASK';
export const UPDATE_DOWNLOAD_PROGRESS = 'UPDATE_DOWNLOAD_PROGRESS';
export const REMOVE_DOWNLOAD_TASK = 'REMOVE_DOWNLOAD_TASK';

export const USER_ASSIGNED_PROJECTS_GET_REQUEST = 'USER_ASSIGNED_PROJECTS_GET_REQUEST';
export const USER_ASSIGNED_PROJECTS_GET_SUCCESS = 'USER_ASSIGNED_PROJECTS_GET_SUCCESS';
export const USER_ASSIGNED_PROJECTS_GET_FAILURE = 'USER_ASSIGNED_PROJECTS_GET_FAILURE';

export const PROJECT_DETAILS_BY_PROJECT_ID_GET_REQUEST =
  'PROJECT_DETAILS_BY_PROJECT_ID_GET_REQUEST';
export const PROJECT_DETAILS_BY_PROJECT_ID_GET_SUCCESS =
  'PROJECT_DETAILS_BY_PROJECT_ID_GET_SUCCESS';
export const PROJECT_DETAILS_BY_PROJECT_ID_GET_FAILURE =
  'PROJECT_DETAILS_BY_PROJECT_ID_GET_FAILURE';

export const USER_REPORT_GROUPS_GET_REQUEST = 'USER_REPORT_GROUPS_GET_REQUEST';
export const USER_REPORT_GROUPS_GET_SUCCESS = 'USER_REPORT_GROUPS_GET_SUCCESS';
export const USER_REPORT_GROUPS_GET_FAILURE = 'USER_REPORT_GROUPS_GET_FAILURE';

export const BANDWIDTH_GET_REQUEST = 'BANDWIDTH_GET_REQUEST';
export const BANDWIDTH_GET_SUCCESS = 'BANDWIDTH_GET_SUCCESS';
export const BANDWIDTH_GET_FAILURE = 'BANDWIDTH_GET_FAILURE';

export const SERVICE_INFO_GET_REQUEST = 'SERVICE_INFO_GET_REQUEST';
export const SERVICE_INFO_GET_SUCCESS = 'SERVICE_INFO_GET_SUCCESS';
export const SERVICE_INFO_GET_FAILURE = 'SERVICE_INFO_GET_FAILURE';

export const HARDWARE_GET_REQUEST = 'HARDWARE_GET_REQUEST';
export const HARDWARE_GET_SUCCESS = 'HARDWARE_GET_SUCCESS';
export const HARDWARE_GET_FAILURE = 'HARDWARE_GET_FAILURE';

export const ATTENDANCE_GET_REQUEST = 'ATTENDANCE_GET_REQUEST';
export const ATTENDANCE_GET_SUCCESS = 'ATTENDANCE_GET_SUCCESS';
export const ATTENDANCE_GET_FAILURE = 'ATTENDANCE_GET_FAILURE';

export const GEO_LOCATION_GET_REQUEST = 'GEO_LOCATION_GET_REQUEST';
export const GEO_LOCATION_GET_SUCCESS = 'GEO_LOCATION_GET_SUCCESS';
export const GEO_LOCATION_GET_FAILURE = 'GEO_LOCATION_GET_FAILURE';

interface WorktimeGetRequestAction {
  type: typeof WORKTIME_GET_REQUEST;
  payload: {};
}

interface WorktimeGetSuccessAction {
  type: typeof WORKTIME_GET_SUCCESS;
  payload: any;
}

interface WorktimeGetFailureAction {
  type: typeof WORKTIME_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ReportExportRequestAction {
  type: typeof REPORT_EXPORT_REQUEST;
  payload: {};
}

interface ReportExportSuccessAction {
  type: typeof REPORT_EXPORT_SUCCESS;
  payload: any;
}

interface ReportExportFailureAction {
  type: typeof REPORT_EXPORT_FAILURE;
  payload: {
    error: string;
  };
}

interface ReportDownloadAddTaskAction {
  type: typeof ADD_DOWNLOAD_TASK;
  payload: any;
}

interface ReportDownloadUpdateProgressAction {
  type: typeof UPDATE_DOWNLOAD_PROGRESS;
  payload: any;
}

interface ReportDownloadRemoveTaskAction {
  type: typeof REMOVE_DOWNLOAD_TASK;
  payload: any;
}

interface UserAssignedProjectsGetRequestAction {
  type: typeof USER_ASSIGNED_PROJECTS_GET_REQUEST;
  payload: {};
}

interface UserAssignedProjectsGetSuccessAction {
  type: typeof USER_ASSIGNED_PROJECTS_GET_SUCCESS;
  payload: any;
}

interface UserAssignedProjectsGetFailureAction {
  type: typeof USER_ASSIGNED_PROJECTS_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ProjectDetailsByProjectIdGetRequestAction {
  type: typeof PROJECT_DETAILS_BY_PROJECT_ID_GET_REQUEST;
  payload: {};
}

interface ProjectDetailsByProjectIdGetSuccessAction {
  type: typeof PROJECT_DETAILS_BY_PROJECT_ID_GET_SUCCESS;
  payload: any;
}

interface ProjectDetailsByProjectIdGetFailureAction {
  type: typeof PROJECT_DETAILS_BY_PROJECT_ID_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface UserReportGetRequestAction {
  type: typeof USER_REPORT_GROUPS_GET_REQUEST;
  payload: {};
}

interface UserReportGetSuccessAction {
  type: typeof USER_REPORT_GROUPS_GET_SUCCESS;
  payload: any;
}

interface UserReportGetFailureAction {
  type: typeof USER_REPORT_GROUPS_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface BandwidthGetRequestAction {
  type: typeof BANDWIDTH_GET_REQUEST;
  payload: {};
}

interface BandwidthGetSuccessAction {
  type: typeof BANDWIDTH_GET_SUCCESS;
  payload: any;
}

interface BandwidthGetFailureAction {
  type: typeof BANDWIDTH_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface ServiceInfoGetRequestAction {
  type: typeof SERVICE_INFO_GET_REQUEST;
  payload: {};
}

interface ServiceInfoGetSuccessAction {
  type: typeof SERVICE_INFO_GET_SUCCESS;
  payload: any;
}

interface ServiceInfoGetFailureAction {
  type: typeof SERVICE_INFO_GET_FAILURE;
  payload: {
    error: string;
  };
}
interface HardwareGetRequestAction {
  type: typeof HARDWARE_GET_REQUEST;
  payload: {};
}

interface HardwareGetSuccessAction {
  type: typeof HARDWARE_GET_SUCCESS;
  payload: any;
}

interface HardwareGetFailureAction {
  type: typeof HARDWARE_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface AttendanceGetRequestAction {
  type: typeof ATTENDANCE_GET_REQUEST;
  payload: {};
}

interface AttendanceGetSuccessAction {
  type: typeof ATTENDANCE_GET_SUCCESS;
  payload: any;
}

interface AttendanceGetFailureAction {
  type: typeof ATTENDANCE_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface GeoLocationGetRequestAction {
  type: typeof GEO_LOCATION_GET_REQUEST;
  payload: {};
}

interface GeoLocationGetSuccessAction {
  type: typeof GEO_LOCATION_GET_SUCCESS;
  payload: any;
}

interface GeoLocationGetFailureAction {
  type: typeof GEO_LOCATION_GET_FAILURE;
  payload: {
    error: string;
  };
}

export type ReportAction =
  | WorktimeGetRequestAction
  | WorktimeGetSuccessAction
  | WorktimeGetFailureAction
  | ReportExportRequestAction
  | ReportExportSuccessAction
  | ReportExportFailureAction
  | ReportDownloadAddTaskAction
  | ReportDownloadUpdateProgressAction
  | ReportDownloadRemoveTaskAction
  | UserAssignedProjectsGetRequestAction
  | UserAssignedProjectsGetSuccessAction
  | UserAssignedProjectsGetFailureAction
  | ProjectDetailsByProjectIdGetRequestAction
  | ProjectDetailsByProjectIdGetSuccessAction
  | ProjectDetailsByProjectIdGetFailureAction
  | UserReportGetRequestAction
  | UserReportGetSuccessAction
  | UserReportGetFailureAction
  | BandwidthGetRequestAction
  | BandwidthGetSuccessAction
  | BandwidthGetFailureAction
  | ServiceInfoGetRequestAction
  | ServiceInfoGetSuccessAction
  | ServiceInfoGetFailureAction
  | HardwareGetRequestAction
  | HardwareGetSuccessAction
  | HardwareGetFailureAction
  | AttendanceGetRequestAction
  | AttendanceGetSuccessAction
  | AttendanceGetFailureAction
  | GeoLocationGetRequestAction
  | GeoLocationGetSuccessAction
  | GeoLocationGetFailureAction;
