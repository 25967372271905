/**
 *
 * NOTE
 * use define routes in route.ts file
 * implement this structure to handle all routes in one place
 * if its hard to maintain. please devide routes seperately (public/ private/ app/ or each layout wise)
 * accoring to the current code structure there is lazy loading issue
 *
 * */

import { ComponentClass, FC, lazy } from 'react';
import { RoleTypes } from 'session';

//Pages
const Login = lazy(() => import('../pages/auth/Login'));
const Register = lazy(() => import('../pages/auth/Register'));
const TwoFactor = lazy(() => import('../pages/auth/TwoFactorAuth'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const ChangeInitialPassword = lazy(() => import('../pages/auth/ChangeInitialPassword'));

const Dashboard = lazy(() => import('../pages/dashboard'));
const Started = lazy(() => import('../pages/start'));
const Reports = lazy(() => import('../pages/reports'));
const CalendarPage = lazy(() => import('../pages/calendar'));
const Clients = lazy(() => import('../pages/clients'));
const ClientsImport = lazy(() => import('../pages/clients/import'));
const Projects = lazy(() => import('../pages/projects/listing'));
const ProjectSettings = lazy(() => import('../pages/projects/settings'));
const ProjectsAndTasksImport = lazy(() => import('../pages/projects/import'));
const Ratings = lazy(() => import('../pages/settings/ratings'));
const Billing = lazy(() => import('../pages/billing'));
// const GDPR = lazy(() => import('../pages/settings/gdpr'));
const CompanyProfile = lazy(() => import('../pages/company/CompanyProfile'));
const Configurations = lazy(() => import('../pages/settings/Configurations'));
const ReportGroups = lazy(() => import('../pages/settings/report-groups'));
const WorkSchedules = lazy(() => import('../pages/work-schedules'));
const Employees = lazy(() => import('../pages/employees/'));
const Error404 = lazy(() => import('../pages/errors/Error404'));
const Error500 = lazy(() => import('../pages/errors/Error500'));
const Error401 = lazy(() => import('../pages/errors/Error401'));
const Error403 = lazy(() => import('../pages/errors/Error403'));

//Layouts
const BaseLayout = lazy(() => import('../layouts/base'));
const BlankLayout = lazy(() => import('../layouts/BlankLayout'));
// const BaseLayout  = require("../layouts/base");
// const BlankLayout  = require("../layouts/BlankLayout");

enum RouteType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

interface RouteConfig {
  /**
   * Name of the route
   */
  name: string;
  /**
   * Path to the route
   */
  path: string;
  /**
   * Type of the route
   * private - authenticated routes
   * public - routes can access pulically
   */
  type?: RouteType;
  /**
   * Compnent to be render
   * Default consider as private
   */
  component: ComponentClass<any, any> | FC<any>;
  /**
   * Permissions that need to access
   */
  permissions?: string[];
  /**
   * Roles that can access
   * If empty all roles can be access.
   */
  authority?: RoleTypes[];
  /**
   * Nested Routes list
   */
  routes?: RouteConfig[];
  /**
   * Flag to indicate whether the route is displayed in sidebar drawer
   */
  hideInMenu?: boolean;
  /**
   * If in menu side bar icon. consider only first level routes
   */
  icon?: string;
}

const routes: RouteConfig[] = [
  {
    name: 'Login',
    path: '/login',
    type: RouteType.PUBLIC,
    component: Login,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Register',
    path: '/register',
    type: RouteType.PUBLIC,
    component: Register,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Forgot Passord',
    path: '/forgot-password',
    type: RouteType.PUBLIC,
    component: ForgotPassword,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Two Factor Authentication',
    path: '/two-factor-auth',
    type: RouteType.PUBLIC,
    component: TwoFactor,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Reset Password',
    path: '/change-password/:id/:token',
    type: RouteType.PUBLIC,
    component: ResetPassword,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Password Setup Password',
    path: '/change-initial-password',
    type: RouteType.PRIVATE,
    component: ChangeInitialPassword,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
  {
    name: 'Base Layout',
    path: '/',
    component: BaseLayout,
    permissions: [],
    authority: [],
    routes: [
      {
        name: 'Get Started',
        path: '/get-started',
        component: Started,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'GetStartIcon',
      },
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        permissions: [],
        authority: ['SUPER_ADMIN', 'MANAGER'],
        icon: 'DashboardIcon',
      },
      {
        name: 'Reports',
        path: '/reports',
        component: Reports,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'ReportIcon',
      },
      {
        name: 'Projects',
        path: '/projects',
        component: Projects,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'ProjectIcon',
      },
      {
        name: 'ProjectSettings',
        path: '/project/settings/:projectId',
        component: ProjectSettings,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        hideInMenu: true,
      },
      {
        name: 'ProjectsAndTasksImport',
        path: '/projects/import',
        component: ProjectsAndTasksImport,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        hideInMenu: true,
      },
      {
        name: 'Employees',
        path: '/employees',
        component: Employees,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'EmployeeIcon',
      },
      {
        name: 'Calendar',
        path: '/calendar',
        component: CalendarPage,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN'],
        icon: 'CalendarIcon',
      },
      {
        name: 'Clients',
        path: '/clients',
        component: Clients,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'ClientIcon',
      },
      {
        name: 'Clients',
        path: '/clients/import',
        component: ClientsImport,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        hideInMenu: true,
      },
      {
        name: 'Settings',
        path: '/settings',
        component: BlankLayout,
        permissions: [],
        authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
        icon: 'SettingsIcon',
        routes: [
          {
            name: 'Ratings',
            path: '/settings/ratings',
            component: Ratings,
            permissions: [],
            authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            name: 'Billing',
            path: '/settings/billing',
            component: Billing,
            permissions: [],
            authority: ['SUPER_ADMIN'],
          },
          // {
          //   name: 'GDPR',
          //   path: '/settings/gdpr',
          //   component: GDPR,
          //   permissions: [],
          //   authority: [],
          // },
          {
            name: 'Company Profile',
            path: '/settings/company-profile',
            component: CompanyProfile,
            permissions: [],
            authority: ['SUPER_ADMIN', 'ADMIN'],
          },
          {
            name: 'Configurations',
            path: '/settings/configurations',
            component: Configurations,
            permissions: [],
            authority: ['SUPER_ADMIN', 'ADMIN'],
          },
          {
            name: 'Report Groups',
            path: '/settings/report-groups',
            component: ReportGroups,
            permissions: [],
            authority: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            name: 'Work Schedules',
            path: '/settings/work-schedules',
            component: WorkSchedules,
            permissions: [],
            authority: ['SUPER_ADMIN', 'ADMIN'],
          },
        ],
      },
      {
        name: 'Unauthorized',
        path: '/401',
        component: Error401,
        permissions: [],
        authority: [],
        hideInMenu: true,
      },
      {
        name: 'Access Deneid',
        path: '/403',
        component: Error403,
        permissions: [],
        authority: [],
        hideInMenu: true,
      },
      {
        name: 'Error 404',
        path: '*',
        component: Error404,
        permissions: [],
        authority: [],
        hideInMenu: true,
      },
      {
        name: 'Error 500',
        path: '/500',
        component: Error500,
        permissions: [],
        authority: [],
        hideInMenu: true,
      },
    ],
  },
  {
    name: 'Error 404',
    path: '*',
    component: Error404,
    permissions: [],
    authority: [],
    hideInMenu: true,
  },
];

export default routes;
