import { ThunkAction } from 'redux-thunk';

import {
  getTeamsPulseReport as getTeamsPulseReportRequest,
  getProductivityReport as getProductivityReportRequest,
  getActivitySummaryReport as getActivitySummaryReportRequest,
  getTopProjectReport as getTopProjectReportRequest,
  getUserLocations as getUserLocationsRequest,
} from 'services/api/dashboard';
import {
  ACTIVITY_SUMMARY_GET_FAILURE,
  ACTIVITY_SUMMARY_GET_REQUEST,
  ACTIVITY_SUMMARY_GET_SUCCESS,
  USER_LOCATIONS_GET_FAILURE,
  USER_LOCATIONS_GET_REQUEST,
  USER_LOCATIONS_GET_SUCCESS,
  PRODUCTIVITY_GET_FAILURE,
  PRODUCTIVITY_GET_REQUEST,
  PRODUCTIVITY_GET_SUCCESS,
  ReportAction,
  TEAMS_PULSE_GET_FAILURE,
  TEAMS_PULSE_GET_REQUEST,
  TEAMS_PULSE_GET_SUCCESS,
  TOP_PROJECTS_GET_FAILURE,
  TOP_PROJECTS_GET_REQUEST,
  TOP_PROJECTS_GET_SUCCESS,
  USER_LOCATIONS_UPDATE_REQUEST,
  USER_LOCATIONS_UPDATE_SUCCESS,
  USER_LOCATIONS_UPDATE_FAILURE,
} from 'store/actionTypes/dashboardActionTypes';
import { RootState } from 'store/reducers';
import { userLocation } from 'types/models/dashboard';

export const getTeamsPulseReport = (
  queryOptions: {},
  filterIds: string[]
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: TEAMS_PULSE_GET_REQUEST, payload: [] });
    try {
      const response = await getTeamsPulseReportRequest(queryOptions, filterIds);
      const data = response.data;
      console.log([response]);

      dispatch({ type: TEAMS_PULSE_GET_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({ type: TEAMS_PULSE_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const getProductivityReport = (
  queryOptions: {},
  filterIds: string[]
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTIVITY_GET_REQUEST, payload: [] });
    try {
      const response = await getProductivityReportRequest(queryOptions, filterIds);
      const data = response.data;

      dispatch({ type: PRODUCTIVITY_GET_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({ type: PRODUCTIVITY_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const getActivitySummaryReport = (
  queryOptions: {},
  filterIds: string[]
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: ACTIVITY_SUMMARY_GET_REQUEST, payload: [] });
    try {
      const response = await getTeamsPulseReportRequest(queryOptions, filterIds);
      const data = response.data;

      dispatch({ type: ACTIVITY_SUMMARY_GET_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({ type: ACTIVITY_SUMMARY_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const getTopProjectsReport = (
  queryOptions: {},
  filterIds: string[]
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: TOP_PROJECTS_GET_REQUEST, payload: [] });
    try {
      const response = await getTopProjectReportRequest(queryOptions, filterIds);
      const data = response.data;

      dispatch({ type: TOP_PROJECTS_GET_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({ type: TOP_PROJECTS_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const getUserLocations = (
  queryOptions: {},
  filterIds: string[]
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: USER_LOCATIONS_GET_REQUEST, payload: [] });
    try {
      const response = await getUserLocationsRequest(queryOptions, filterIds);
      const data = response.data;

      dispatch({ type: USER_LOCATIONS_GET_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({ type: USER_LOCATIONS_GET_FAILURE, payload: { error: error.message } });
    }
  };
};

export const updateUserLocation = (
  userId: string,
  payload: userLocation,
): ThunkAction<void, RootState, null, ReportAction> => {
  return async (dispatch) => {
    dispatch({ type: USER_LOCATIONS_UPDATE_REQUEST, payload: [] });
    console.log({payload})
    try {
      dispatch({ type: USER_LOCATIONS_UPDATE_SUCCESS, payload });
    } catch (error: any) {
      dispatch({ type: USER_LOCATIONS_UPDATE_FAILURE, payload: { error: error.message } });
    }
  };
};
