/**
 *
 * NOTE
 * use define routes in route.ts file
 * implement this structure to handle all routes in one place
 * if its hard to maintain. please devide routes seperately (public/ private/ app/ or each layout wise)
 * accoring to the current code structure there is lazy loading issue
 *
 * */

import { isEmpty } from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';
import { RoleTypes, authenticated, useSession } from 'session';

export const RouteHandler = ({ children, permissions, authority, type }: any) => {
  /* hook for local storage session change */
  const { session } = useSession();
  const location = useLocation();

  const role = session?.role;
  const roleName = role?.name;
  const rolePermissions = role?.permissions || [];
  /* TDOD: move to context or store */
  const isAuthenticated = Boolean(session && session?.id) && authenticated;
  const isClientActive = session?.isClientActive;
  const hasCompanySetup = session?.currentCompany?.id && !isEmpty(session?.companies);

  /**
   * Check permissions
   */
  const hasPermission = checkUserPermission(rolePermissions);
  /**
   * Check role has access
   */
  const hasAuthority = checkAuthority(authority, roleName);

  /**
   * has session and in Login/signUp page redirect to the dashboard
   */
  if (
    (location.pathname === '/login' || location.pathname === '/register') &&
    isAuthenticated &&
    hasPermission
  ) {
    return <Navigate to='/dashboard' replace />;
  }

  /**
   * allow public routes
   */
  if (type === 'public') {
    return children;
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  if (!(hasAuthority && hasPermission)) {
    return <Navigate to='/403' replace />;
  }

  /**
   * initial login redirect to password setup
   */
  if (!isClientActive && location.pathname !== '/change-initial-password') {
    return <Navigate to='/change-initial-password' replace />;
  }

  /**
   * redirect to company setup
   */
  if (
    session?.role?.name === 'SUPER_ADMIN' &&
    isClientActive &&
    isAuthenticated &&
    hasPermission &&
    !hasCompanySetup &&
    location.pathname !== '/get-started'
  ) {
    return <Navigate to='/get-started' replace />;
  }

  /**
   * root route redirection after authenticate add role/permission wise redirections here
   */
  if (location.pathname === '/' && isAuthenticated && hasPermission) {
    return <Navigate to='/get-started' replace />; 
  }

  return children;
};

/**
 * Check role has access
 */
export const checkAuthority = (authority: RoleTypes[], roleName: any) => {
  if (!roleName) {
    return false;
  }
  if (!authority?.length) {
    return true;
  }
  return authority.includes(roleName);
};

/*
 * use this if want to check with role permissions
 */
export const checkUserPermission = (_rolePermissions: string[]) => {
  return true;
};

export default RouteHandler;
