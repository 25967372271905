import {
  GET_USER_ONLINE_FAILURE,
  GET_USER_ONLINE_REQUEST,
  GET_USER_ONLINE_SUCCESS,
  SET_USER_ONLINE_FAILURE,
  SET_USER_ONLINE_REQUEST,
  SET_USER_ONLINE_SUCCESS,
  USER_GET_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  UserAction,
} from 'store/actionTypes/userActionTypes';
import { User, UserOnlineStatus } from 'types/models/user';

interface UserState {
  users: { data: User[]; loading: boolean; error: string | null;  };
  usersOnlineStatus: {
    data: UserOnlineStatus[];
    loading: boolean;
    error: string | null;
  };
}

// const initialUserState: User = {
//   id: '',
//   firstName: '',
//   lastName: '',
//   email: '',
//   companyId: '',
//   roleId: '',
//   managerId: '',
//   role: {
//     id: '',
//     name: '',
//     description: '',
//   },
//   licenseId: '',
//   isActive: true,
//   isSelected: false,
//   isSelectOfflinTask: false,
//   isGdpr: false,
//   isAllowOfflineTask: false,
//   isAllowScreenCapturing: false,
// };

const initialState: UserState = {
  users: {
    data: [],
    loading: false,
    error: null,
  },
  usersOnlineStatus: {
    data: [],
    loading: false,
    error: null,
  },
};

const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case USER_GET_REQUEST:
      return {
        ...state,
        users: { ...state.users, loading: true },
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        users: { ...state.users, data: action.payload, loading: false },
      };
    case USER_GET_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.payload.error,
        },
      };

    case SET_USER_ONLINE_REQUEST:
      return {
        ...state,
        usersOnlineStatus: { ...state.usersOnlineStatus, loading: true },
      };
    case SET_USER_ONLINE_SUCCESS:
      return {
        ...state,
        usersOnlineStatus: {
          ...state.usersOnlineStatus?.data,
          data: state.usersOnlineStatus.data?.length
            ? state.usersOnlineStatus.data.map((user) =>
                user.userId === action.payload.userId ? action.payload : user
              )
            : [action.payload],
          error: null,
          loading: false,
        },
      };
    case SET_USER_ONLINE_FAILURE:
      return {
        ...state,
        usersOnlineStatus: {
          ...state.usersOnlineStatus,
          loading: false,
          error: action.payload.error,
        },
      };

    case GET_USER_ONLINE_REQUEST:
      return {
        ...state,
        usersOnlineStatus: { ...state.usersOnlineStatus, loading: true },
      };

    case GET_USER_ONLINE_SUCCESS:
      return {
        ...state,
        usersOnlineStatus: {
          ...state.usersOnlineStatus?.data,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_USER_ONLINE_FAILURE:
      return {
        ...state,
        usersOnlineStatus: {
          ...state.usersOnlineStatus,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
