import { isEmpty } from 'lodash';
import {
  ADD_DOWNLOAD_TASK,
  REMOVE_DOWNLOAD_TASK,
  REPORT_EXPORT_FAILURE,
  REPORT_EXPORT_REQUEST,
  REPORT_EXPORT_SUCCESS,
  ReportAction,
  UPDATE_DOWNLOAD_PROGRESS,
  WORKTIME_GET_FAILURE,
  WORKTIME_GET_REQUEST,
  WORKTIME_GET_SUCCESS,
  USER_ASSIGNED_PROJECTS_GET_FAILURE,
  USER_ASSIGNED_PROJECTS_GET_REQUEST,
  USER_ASSIGNED_PROJECTS_GET_SUCCESS,
  PROJECT_DETAILS_BY_PROJECT_ID_GET_REQUEST,
  PROJECT_DETAILS_BY_PROJECT_ID_GET_FAILURE,
  PROJECT_DETAILS_BY_PROJECT_ID_GET_SUCCESS,
  USER_REPORT_GROUPS_GET_REQUEST,
  USER_REPORT_GROUPS_GET_SUCCESS,
  USER_REPORT_GROUPS_GET_FAILURE,
  BANDWIDTH_GET_REQUEST,
  BANDWIDTH_GET_SUCCESS,
  BANDWIDTH_GET_FAILURE,
  SERVICE_INFO_GET_REQUEST,
  SERVICE_INFO_GET_SUCCESS,
  SERVICE_INFO_GET_FAILURE,
  HARDWARE_GET_REQUEST,
  HARDWARE_GET_SUCCESS,
  HARDWARE_GET_FAILURE,
  ATTENDANCE_GET_REQUEST,
  ATTENDANCE_GET_SUCCESS,
  ATTENDANCE_GET_FAILURE,
  GEO_LOCATION_GET_REQUEST,
  GEO_LOCATION_GET_SUCCESS,
  GEO_LOCATION_GET_FAILURE,
} from 'store/actionTypes/reportActionTypes';
import {
  UserAssignedProjects,
  ServiceInfoData,
  WorkTime,
  UserHardWareData,
  AttendanceData,
  GeoLocationData,
} from 'types/models/report';
import { Group } from 'types/models/user-groups';

export interface WorkTimeState {
  data: WorkTime[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface DownloadTask {
  queueId: string;
  fileName: string;
  progress: number;
  error: string | null;
}

export interface ExportReport {
  data: any;
  loading: boolean;
  error: string | null;
}

export interface UserAssignedProjectsState {
  data: UserAssignedProjects[];
  loading: boolean;
  error: string | null;
}

export interface ProjectDetailsByProjectIdState {
  data: any[];
  loading: boolean;
  error: string | null;
}
export interface UserReportGroupsState {
  data: Group[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface BandwidthState {
  data: any; //will defined when front integration
  total: number;
  loading: boolean;
  error: string | null;
}

export interface ServiceInfoState {
  data: {
    data: ServiceInfoData[];
    count: number;
  };
  loading: boolean;
  error: string | null;
}

export interface HardwateState {
  data: UserHardWareData[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface AttendanceState {
  data: AttendanceData[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface GeoLocationState {
  data: GeoLocationData[];
  total: number;
  loading: boolean;
  error: string | null;
}

export interface ReportState {
  worktime: WorkTimeState;
  downloadTasks: DownloadTask[];
  exportReport: ExportReport;
  userGroups: UserReportGroupsState;
  userAssignedProjects: UserAssignedProjectsState;
  projectDetailsByProjectId: ProjectDetailsByProjectIdState;
  bandwidth: BandwidthState;
  serviceInfo: ServiceInfoState;
  hardware: HardwateState;
  attendance: AttendanceState;
  geoLocation: GeoLocationState;
}

const initialState: ReportState = {
  worktime: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  downloadTasks: [],
  exportReport: {
    data: {},
    loading: false,
    error: '',
  },
  userAssignedProjects: {
    data: [],
    loading: false,
    error: '',
  },
  projectDetailsByProjectId: {
    data: [],
    loading: false,
    error: '',
  },
  userGroups: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  bandwidth: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  serviceInfo: {
    data: {
      data: [],
      count: 0,
    },
    loading: false,
    error: null,
  },
  hardware: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  attendance: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  geoLocation: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
};

const userReducer = (state = initialState, action: ReportAction): ReportState => {
  switch (action.type) {
    case WORKTIME_GET_REQUEST:
      return {
        ...state,
        worktime: { ...state.worktime, loading: true },
      };
    case WORKTIME_GET_SUCCESS:
      return {
        ...state,
        worktime: {
          ...state.worktime,
          data: action.payload?.worktime || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case WORKTIME_GET_FAILURE:
      return {
        ...state,
        worktime: {
          ...state.worktime,
          loading: false,
          error: action.payload.error,
        },
      };
    case REPORT_EXPORT_REQUEST:
      return {
        ...state,
        exportReport: { ...state.exportReport, loading: true },
      };
    case REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        exportReport: {
          ...state.exportReport,
          data: action.payload?.exportReport || {},
          loading: false,
        },
      };
    case REPORT_EXPORT_FAILURE:
      return {
        ...state,
        exportReport: {
          ...state.exportReport,
          loading: false,
          error: action.payload.error,
        },
      };
    case ADD_DOWNLOAD_TASK:
      return {
        ...state,
        downloadTasks: [...state.downloadTasks, action.payload.task],
      };
    case UPDATE_DOWNLOAD_PROGRESS:
      const updatedTasks = state.downloadTasks.map((task) =>
        task.queueId === action.payload.queueId
          ? { ...task, fileName: action.payload.fileName, progress: action.payload.progress }
          : task
      );
      return {
        ...state,
        downloadTasks: updatedTasks,
      };
    case REMOVE_DOWNLOAD_TASK:
      const filteredTasks = state.downloadTasks.filter(
        (task) => task.queueId !== action.payload.queueId
      );
      return {
        ...state,
        downloadTasks: filteredTasks,
      };

    case USER_ASSIGNED_PROJECTS_GET_REQUEST:
      return {
        ...state,
        userAssignedProjects: { ...state.userAssignedProjects, loading: true },
      };

    case USER_ASSIGNED_PROJECTS_GET_SUCCESS:
      return {
        ...state,
        userAssignedProjects: {
          ...state.userAssignedProjects,
          data: !isEmpty(action.payload?.dataList) ? action.payload?.dataList : [],
          loading: false,
        },
      };

    case USER_ASSIGNED_PROJECTS_GET_FAILURE:
      return {
        ...state,
        userAssignedProjects: {
          ...state.userAssignedProjects,
          loading: false,
          error: action.payload.error,
        },
      };

    case PROJECT_DETAILS_BY_PROJECT_ID_GET_REQUEST:
      return {
        ...state,
        projectDetailsByProjectId: { ...state.projectDetailsByProjectId, loading: true },
      };

    case PROJECT_DETAILS_BY_PROJECT_ID_GET_SUCCESS:
      return {
        ...state,
        projectDetailsByProjectId: {
          ...state.projectDetailsByProjectId,
          data: !isEmpty(action.payload?.dataList) ? action.payload.dataList : [],
          loading: false,
        },
      };

    case PROJECT_DETAILS_BY_PROJECT_ID_GET_FAILURE:
      return {
        ...state,
        projectDetailsByProjectId: {
          ...state.projectDetailsByProjectId,
          loading: false,
          error: action.payload.error,
        },
      };

    case USER_REPORT_GROUPS_GET_REQUEST:
      return {
        ...state,
        userGroups: { ...state.userGroups, loading: true },
      };
    case USER_REPORT_GROUPS_GET_SUCCESS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          data: action.payload?.rows || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case USER_REPORT_GROUPS_GET_FAILURE:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          loading: false,
          error: action.payload.error,
        },
      };

    case BANDWIDTH_GET_REQUEST:
      return {
        ...state,
        bandwidth: { ...state.bandwidth, loading: true },
      };
    case BANDWIDTH_GET_SUCCESS:
      return {
        ...state,
        bandwidth: {
          ...state.bandwidth,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case BANDWIDTH_GET_FAILURE:
      return {
        ...state,
        bandwidth: {
          ...state.bandwidth,
          loading: false,
          error: action.payload.error,
        },
      };

    case SERVICE_INFO_GET_REQUEST:
      return {
        ...state,
        serviceInfo: { ...state.serviceInfo, loading: true },
      };

    case SERVICE_INFO_GET_SUCCESS:
      return {
        ...state,
        serviceInfo: {
          ...state.serviceInfo,
          data: action.payload?.data || {},
          loading: false,
        },
      };

    case SERVICE_INFO_GET_FAILURE:
      return {
        ...state,
        serviceInfo: {
          ...state.serviceInfo,
        },
      };

    case HARDWARE_GET_REQUEST:
      return {
        ...state,
        hardware: { ...state.hardware, loading: true },
      };
    case HARDWARE_GET_SUCCESS:
      return {
        ...state,
        hardware: {
          ...state.hardware,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case HARDWARE_GET_FAILURE:
      return {
        ...state,
        hardware: {
          ...state.hardware,
          loading: false,
          error: action.payload.error,
        },
      };

    case ATTENDANCE_GET_REQUEST:
      return {
        ...state,
        attendance: { ...state.attendance, loading: true },
      };
    case ATTENDANCE_GET_SUCCESS:
      return {
        ...state,
        attendance: {
          ...state.attendance,
          data: action.payload?.userAttendance || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case ATTENDANCE_GET_FAILURE:
      return {
        ...state,
        attendance: {
          ...state.attendance,
          loading: false,
          error: action.payload.error,
        },
      };

    case GEO_LOCATION_GET_REQUEST:
      return {
        ...state,
        geoLocation: { ...state.geoLocation, loading: true },
      };
    case GEO_LOCATION_GET_SUCCESS:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          data: action.payload?.dataList || [],
          total: action.payload?.count || 0,
          loading: false,
        },
      };
    case GEO_LOCATION_GET_FAILURE:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          loading: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
