import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/configureStore";

import "react-big-calendar/lib/sass/styles.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./assets/css/gobal.css";
import "antd/dist/reset.css";
import "./assets/icons/fonticon/fonticon.css";
import "./assets/icons/keenicons/duotone/style.css";
import "./assets/icons/keenicons/outline/style.css";
import "./assets/icons/keenicons/solid/style.css";
import "./assets/css/antd.customize.scss";
// import "./assets/css/bootstrap.min.css";
// import "./assets/css/plugins.css";
import "./assets/css/template.css";
import "./assets/css/style.scss";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { checkAuth } from "session";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

checkAuth();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
