import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarStatus: false,
  sidebarStatusMobile: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    sidebarToggle: (state) => {
      state.sidebarStatus = !state.sidebarStatus
    },

    // mobile sidebar toggle
    sidebarToggleMobile: (state) => {
      state.sidebarStatusMobile = !state.sidebarStatusMobile
    },

  },
})

// Action creators are generated for each case reducer function
export const { sidebarToggle, sidebarToggleMobile } = sidebarSlice.actions

export default sidebarSlice.reducer