import moment from 'moment';
import { KeyValueObject } from 'types/common';

export const SESSION_KEY = 'SESSION_KEY';

export const OAUTH_KEY = 'OAUTH_KEY';

export const API_URL = process.env.REACT_APP_API_URL || 'http:localhost:5000';

export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'http://localhost:5000';

export const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

export const ROUTE_BASE_NAME = process.env.REACT_APP_ROUTE_BASE_NAME || 'app';

export const WEBSITE_TOC_URL =
  process.env.REACT_APP_WEBSITE_TOC_URL || 'https://workshiftly.com/terms-of-service';

export const WEBSITE_PAYMENT_PLANS_URL =
  process.env.REACT_APP_WEBSITE_PAYMENT_PLANS_URL || 'https://workshiftly.com/#pricing';

export const WEBSITE_CONTACT_US_URL =
  process.env.REACT_APP_WEBSITE_CONTACT_US_URL || 'https://workshiftly.com/contactus';

export const WEBSITE_PRICING_URL =
  process.env.REACT_APP_REACT_APP_WEBSITE_PRICING_URL || 'https://workshiftly.com/#pricing';

export const CLIENT_APP_DOWNLOAD_URL =
  process.env.REACT_APP_CLIENT_APP_DOWNLOAD_URL || 'https://workshiftly.com/download';

export const WEBSITE_EULA_URL =
  process.env.REACT_APP_WEBSITE_EULA_URL || 'https://workshiftly.com/eula';

export const JIRA_URL = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.REACT_APP_JIRA_CLIENT_ID}&scope=read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20manage%3Ajira-data-provider%20manage%3Ajira-webhook%20write%3Ajira-work%20read%3Ajira-user%20offline_access
&redirect_uri=${process.env.REACT_APP_JIRA_CALLBACK_URL}
&response_type=code&prompt=consent`;

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

export const NUMBER_OF_FREE_LICENSES = process.env.REACT_APP_NUMBER_OF_FREE_LICENSES
  ? parseInt(process.env.REACT_APP_NUMBER_OF_FREE_LICENSES)
  : 3;

export const USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  MANAGER: 'MANAGER',
};

export const timeFormats = [
  {
    value: '24_HOURS',
    label: '24 Hours',
    name: '24_HOURS',
  },
  {
    value: '12_HOURS',
    label: '12 Hours',
    name: '12_HOURS',
  },
];

export const REPORT_EXPORT_METHOD = [
  { value: 'download', label: 'Download Report' },
  { value: 'email', label: 'Email Report' },
];

export const SHIFT_TYPES: KeyValueObject = [
  { id: 'STATIC_SHIFT', name: 'Static Shift', value: 'SS' },
  { id: 'DYNAMIC_SHIFT', name: 'Dynamic Shift', value: 'DS' },
];

/**
 * Week days in Text format - ["Sunday", "Monday", ..... "Saturday"]
 */
export const WEEK_DAYS = moment.weekdays();

/**
 * Week days in short Text format - ["Sun", "Mon", ..... "Sat"]
 */
export const WEEK_DAYS_SHORT = moment.weekdaysShort(true);

/**
 * Months in Text format - ["January", "February", ..... "December"]
 */

export const MONTHS = moment.months();
