import RouteHandler from 'routing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import DownloadStatusBar from 'components/UI/DownloadStatusBar';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUsersOnlineStatus, setUsersOnlineStatus } from 'store/actions/userAction';
import { updateUserLocation } from 'store/actions/dashboardAction';
import { authenticated, useSession } from 'session';
import { socket } from 'utils/socket';
import moment from 'moment';

export default function App() {
  const dispatch: any = useDispatch();
  const { session } = useSession();
  const isAuthenticated = Boolean(session && session?.id) && authenticated;

  const initSocket = useCallback(() => {
    if (isAuthenticated) {
      socket.on('connect', () => {
        console.log('Socket connected');
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      // Listen for user status updates from the server
      socket.on('userStatus', ({ userId, status, lastOnlineAt }) => {
        dispatch(setUsersOnlineStatus(userId, status || 'offline', lastOnlineAt));
      });

      socket.on('userLocation', ({ userId, payload }) => {
        console.log({userId, payload})
        dispatch(updateUserLocation(userId, payload || {}));
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    initSocket();
  }, [initSocket]);

  const fetchOnlineStatus = useCallback(() => {
    if (isAuthenticated) {
      dispatch(
        getUsersOnlineStatus(session?.currentCompany?.id as string, {
          date: moment().format('YYYY-MM-DD'),
        })
      );
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    fetchOnlineStatus();
  }, [fetchOnlineStatus]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <RouteHandler />
      <DownloadStatusBar />
    </GoogleOAuthProvider>
  );
}
