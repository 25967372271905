// import { WorkSchedule } from 'types/models/work-schedule';

export const WORKSCHEDULES_GET_REQUEST = 'WORKSCHEDULES_GET_REQUEST';
export const WORKSCHEDULES_GET_SUCCESS = 'WORKSCHEDULES_GET_SUCCESS';
export const WORKSCHEDULES_GET_FAILURE = 'WORKSCHEDULES_GET_FAILURE';

export const USER_WORKSCHEDULE_GET_REQUEST = 'USER_WORKSCHEDULE_GET_REQUEST';
export const USER_WORKSCHEDULE_GET_SUCCESS = 'USER_WORKSCHEDULE_GET_SUCCESS';
export const USER_WORKSCHEDULE_GET_FAILURE = 'USER_WORKSCHEDULE_GET_FAILURE';

export const ALL_USER_WORKSCHEDULES_GET_SUCCESS = 'ALL_USER_WORKSCHEDULES_GET_SUCCESS';
export const ALL_USER_WORKSCHEDULES_GET_FAILURE = 'ALL_USER_WORKSCHEDULES_GET_FAILURE';

export const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
export const CLEAR_SELECTED_USERS = 'CLEAR_SELECTED_USERS';

export const SET_ACTION_KEY = 'SET_ACTION_KEY';
export const USER_WORKSCHEDULE_CREATE_REQUEST = 'USER_WORKSCHEDULE_CREATE_REQUEST';
export const USER_WORKSCHEDULE_CREATE_SUCCESS = 'USER_WORKSCHEDULE_CREATE_SUCCESS';
export const USER_WORKSCHEDULE_CREATE_FAILURE = 'USER_WORKSCHEDULE_CREATE_FAILURE';

export const USER_WORKSCHEDULE_UPDATE_REQUEST = 'USER_WORKSCHEDULE_UPDATE_REQUEST';
export const USER_WORKSCHEDULE_UPDATE_SUCCESS = 'USER_WORKSCHEDULE_UPDATE_SUCCESS';
export const USER_WORKSCHEDULE_UPDATE_FAILURE = 'USER_WORKSCHEDULE_UPDATE_FAILURE';

export const USER_WORKSCHEDULE_DELETE_REQUEST = 'USER_WORKSCHEDULE_DELETE_REQUEST';
export const USER_WORKSCHEDULE_DELETE_SUCCESS = 'USER_WORKSCHEDULE_DELETE_SUCCESS';
export const USER_WORKSCHEDULE_DELETE_FAILURE = 'USER_WORKSCHEDULE_DELETE_FAILURE';

export const USER_WORKSCHEDULE_BULK_DELETE_REQUEST = 'USER_WORKSCHEDULE_BULK_DELETE_REQUEST';
export const USER_WORKSCHEDULE_BULK_DELETE_SUCCESS = 'USER_WORKSCHEDULE_BULK_DELETE_SUCCESS';
export const USER_WORKSCHEDULE_BULK_DELETE_FAILURE = 'USER_WORKSCHEDULE_BULK_DELETE_FAILURE';

export const USER_WORKSCHEDULE_BULK_UPDATE_REQUEST = 'USER_WORKSCHEDULE_BULK_UPDATE_REQUEST';
export const USER_WORKSCHEDULE_BULK_UPDATE_SUCCESS = 'USER_WORKSCHEDULE_BULK_UPDATE_SUCCESS';
export const USER_WORKSCHEDULE_BULK_UPDATE_FAILURE = 'USER_WORKSCHEDULE_BULK_UPDATE_FAILURE';

interface WorkSchedulesGetRequestAction {
  type: typeof WORKSCHEDULES_GET_REQUEST;
  payload: {};
}

interface WorkSchedulesGetSuccessAction {
  type: typeof WORKSCHEDULES_GET_SUCCESS;
  payload: any;
}

interface WorkSchedulesGetFailureAction {
  type: typeof WORKSCHEDULES_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface UserWorkSchedulesGetRequestAction {
  type: typeof USER_WORKSCHEDULE_GET_REQUEST;
  payload: {};
}

interface UserWorkScheduleGetSuccessAction {
  type: typeof USER_WORKSCHEDULE_GET_SUCCESS;
  payload: any;
}

interface UserWorkScheduleGetFailureAction {
  type: typeof USER_WORKSCHEDULE_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface AllUserWorkSchedulesGetSuccessAction {
  type: typeof ALL_USER_WORKSCHEDULES_GET_SUCCESS;
  payload: any;
}

interface AllUserWorkSchedulesGetFailureAction {
  type: typeof ALL_USER_WORKSCHEDULES_GET_FAILURE;
  payload: {
    error: string;
  };
}

interface SetSelectedUsersAction {
  type: typeof SET_SELECTED_USERS;
  payload: string[];
}

interface ClearSelectedUsersAction {
  type: typeof CLEAR_SELECTED_USERS;
}

interface SetActionKeyAction {
  type: typeof SET_ACTION_KEY;
  payload: string;
}

interface UserWorkSchedulesCreateRequestAction {
  type: typeof USER_WORKSCHEDULE_CREATE_REQUEST;
  payload: {};
}

interface UserWorkScheduleCreateSuccessAction {
  type: typeof USER_WORKSCHEDULE_CREATE_SUCCESS;
  payload: any;
}

interface UserWorkScheduleCreateFailureAction {
  type: typeof USER_WORKSCHEDULE_CREATE_FAILURE;
  payload: {
    error: string;
  };
}

interface UserWorkSchedulesUpdateRequestAction {
  type: typeof USER_WORKSCHEDULE_UPDATE_REQUEST;
  payload: {};
}

interface UserWorkScheduleUpdateSuccessAction {
  type: typeof USER_WORKSCHEDULE_UPDATE_SUCCESS;
  payload: any;
}

interface UserWorkScheduleUpdateFailureAction {
  type: typeof USER_WORKSCHEDULE_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

interface UserWorkSchedulesDeleteRequestAction {
  type: typeof USER_WORKSCHEDULE_DELETE_REQUEST;
  payload: {};
}

interface UserWorkScheduleDeleteSuccessAction {
  type: typeof USER_WORKSCHEDULE_DELETE_SUCCESS;
  payload: any;
}

interface UserWorkScheduleDeleteFailureAction {
  type: typeof USER_WORKSCHEDULE_DELETE_FAILURE;
  payload: {
    error: string;
  };
}

interface UserWorkSchedulesBulkDeleteRequestAction {
  type: typeof USER_WORKSCHEDULE_BULK_DELETE_REQUEST;
  payload: {};
}

interface UserWorkScheduleBulkDeleteSuccessAction {
  type: typeof USER_WORKSCHEDULE_BULK_DELETE_SUCCESS;
  payload: any;
}

interface UserWorkScheduleBulkDeleteFailureAction {
  type: typeof USER_WORKSCHEDULE_BULK_DELETE_FAILURE;
  payload: {
    error: string;
  };
}

interface UserWorkSchedulesBulkUpdateRequestAction {
  type: typeof USER_WORKSCHEDULE_BULK_UPDATE_REQUEST;
  payload: {};
}

interface UserWorkScheduleBulkUpdateSuccessAction {
  type: typeof USER_WORKSCHEDULE_BULK_UPDATE_SUCCESS;
  payload: any;
}

interface UserWorkScheduleBulkUpdateFailureAction {
  type: typeof USER_WORKSCHEDULE_BULK_UPDATE_FAILURE;
  payload: {
    error: string;
  };
}

export type WorkScheduleAction =
  | WorkSchedulesGetRequestAction
  | WorkSchedulesGetSuccessAction
  | WorkSchedulesGetFailureAction
  | UserWorkSchedulesGetRequestAction
  | UserWorkScheduleGetSuccessAction
  | UserWorkScheduleGetFailureAction
  | AllUserWorkSchedulesGetSuccessAction
  | AllUserWorkSchedulesGetFailureAction
  | SetSelectedUsersAction
  | ClearSelectedUsersAction
  | SetActionKeyAction
  | UserWorkSchedulesCreateRequestAction
  | UserWorkScheduleCreateSuccessAction
  | UserWorkScheduleCreateFailureAction
  | UserWorkSchedulesUpdateRequestAction
  | UserWorkScheduleUpdateSuccessAction
  | UserWorkScheduleUpdateFailureAction
  | UserWorkSchedulesDeleteRequestAction
  | UserWorkScheduleDeleteSuccessAction
  | UserWorkScheduleDeleteFailureAction
  | UserWorkSchedulesBulkDeleteRequestAction
  | UserWorkScheduleBulkDeleteSuccessAction
  | UserWorkScheduleBulkDeleteFailureAction
  | UserWorkSchedulesBulkUpdateRequestAction
  | UserWorkScheduleBulkUpdateSuccessAction
  | UserWorkScheduleBulkUpdateFailureAction;
